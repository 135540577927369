import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyValueGrid from "components/KeyValueGrid";
import { FhirQuestionnaireMod } from "models/questionnaire";
import { FhirQuestionnaireResponseMod } from "models/questionnaireResponse";
import { urlBackNestApp } from "routes/urls";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "40px",
  },
  rootExpanded: {
    background: "blue",
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
}));

interface QuestResProps {
  questionnaire: FhirQuestionnaireMod.Questionnaire;
  questionnaireResponse: FhirQuestionnaireResponseMod.QuestionnaireResponse;
  devMode: boolean;
  defaultExpanded?: boolean;
}

export default function QuestRes({
  questionnaire,
  questionnaireResponse,
  devMode,
  defaultExpanded,
}: QuestResProps) {
  const classes = useStyles();
  const [answerMaps, setAnswerMaps] = useState<FhirQuestionnaireResponseMod.QuestionnaireAnswerMap[]>([]);
  
  useEffect(() => {
    fetch(`${urlBackNestApp}/questionaire/answermap/${questionnaire.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      }
    })
      .then(answerMapResponse => answerMapResponse.json())
      .then(answerMapResponse => {
        setAnswerMaps(answerMapResponse);
      });
  }, [questionnaire]);


  const getQuestionnaireReponseSummary = (
    quest: FhirQuestionnaireMod.Questionnaire,
    questRes: FhirQuestionnaireResponseMod.QuestionnaireResponse
  ) => {
    return (
      <Grid container>
        <Grid container item md={4} alignItems="center">
          <Typography>
            {quest.title === undefined
              ? "Questionnaire Response"
              : quest.title + " Questionnaire Response"}
          </Typography>
        </Grid>

        {devMode && (
          <Grid container item direction="row" md={12}>
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="Questionnaire ID"
                value={
                  quest.id === undefined
                    ? ""
                    : quest.resourceType + "/" + quest.id
                }
              />
              <KeyValueGrid
                label="Questionnaire Title"
                value={quest.title === undefined ? "" : quest.title}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  interface simplifiedQuestion {
    text: string;
    linkId: string;
    answers?: simplifiedAnswer[];
    items?: simplifiedQuestion[];
  }

  interface simplifiedAnswer {
    value: string;
    code?: string;
    type: string;
    display?: string;
    system?: string;
    version?: string;
    explanation?: string;
    answerStyle?: string;
  }

  const getNestedValues = (item: FhirQuestionnaireResponseMod.Item) => {
    let simplifiedQuest: simplifiedQuestion = {
      text: item.text === undefined ? "" : item.text,
      linkId: item.linkId,
      answers: [],
      items: [],
    };

    if (item.answer) {
      item.answer.forEach((answer: FhirQuestionnaireResponseMod.Answer) => {
        let valueRes: simplifiedAnswer = {
          value: "",
          type: "",
        };

        if (answer.valueInteger !== undefined) {
          valueRes.type = "valueInteger";
          valueRes.value = String(answer.valueInteger);
        } else if (answer.valueCoding !== undefined) {
          let answerMap = answerMaps && answerMaps instanceof Array && answerMaps?.find((map: FhirQuestionnaireResponseMod.QuestionnaireAnswerMap) => map.questionId === item.linkId);
          valueRes.type = "valueCoding";
          valueRes.value = String(answer.valueCoding.display);
          valueRes.code = answer.valueCoding.code;
          valueRes.display = String(answer.valueCoding.display);
          valueRes.explanation = String(answerMap ? answerMap.answerDisplay : '');
          valueRes.answerStyle = String(answerMap ? answerMap.answerStyle : '');

          if (answer.valueCoding.system) {
            valueRes.system = answer.valueCoding.system;
          }

          if (answer.valueCoding.version) {
            valueRes.system = answer.valueCoding.version;
          }
        } else if (answer.valueString !== undefined) {
          valueRes.type = "valueString";
          valueRes.value = answer.valueString;
        }

        simplifiedQuest.answers?.push(valueRes);
      });
    }

    if (item.item) {
      return (
        <Accordion defaultExpanded={defaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid>{simplifiedQuest.text}</Grid>
              {devMode && (
                <Grid container item direction="row" md={12}>
                  <Grid
                    className={classes.highlight}
                    container
                    item
                    md={12}
                    direction="column"
                  >
                    <KeyValueGrid
                      label="Item Linkid"
                      value={simplifiedQuest.linkId}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <Grid>
            {item.item.map((item: FhirQuestionnaireResponseMod.Item) =>
              getNestedValues(item)
            )}
          </Grid>
        </Accordion>
      );
    }

    return (
      <AccordionDetails>
        <Grid container direction="row">
          <Grid item md={6}>
            <Grid container item>
              <Typography variant="body2" component="h1" paragraph>
                {simplifiedQuest.text}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item direction="row" md={2}>
            <Grid container item direction="row" justifyContent="center">
              <Typography
                variant="body2"
                component="h1"
                paragraph
                style={{ color: "green" }}
              >
                {simplifiedQuest.answers?.map(
                  (item: simplifiedAnswer, index: number) => {
                    let displayData = "";

                    if (index > 0) displayData += ", ";

                    return <span className={item.answerStyle}>{displayData + item.value}</span>;
                  }
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item direction="row" md={4}>
            <Grid container item direction="row" justifyContent="center">
              <Typography
                variant="body2"
                component="h1"
                paragraph
                style={{ color: "green" }}>
                  {simplifiedQuest.answers?.map(
                    (item: simplifiedAnswer, index: number) => {
                      return <span className={item.answerStyle}>{item.explanation}</span>;
                    }
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {devMode && (
          <Grid container direction="row">
            <Grid
              className={classes.highlight}
              container
              item
              md={12}
              direction="column"
            >
              <KeyValueGrid
                label="Answer valueType"
                value={
                  simplifiedQuest.answers
                    ? simplifiedQuest.answers
                        ?.map((item: simplifiedAnswer) => item.type)
                        .join(" ")
                    : ""
                }
              />

              {simplifiedQuest?.answers?.[0]?.display !== undefined && (
                <KeyValueGrid
                  label="Answer Display"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: simplifiedAnswer) => item.display)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.code !== undefined && (
                <KeyValueGrid
                  label="Answer Code"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: simplifiedAnswer) => item.code)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.system !== undefined && (
                <KeyValueGrid
                  label="Answer System"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: simplifiedAnswer) => item.system)
                          .join(" ")
                      : ""
                  }
                />
              )}

              {simplifiedQuest?.answers?.[0]?.version !== undefined && (
                <KeyValueGrid
                  label="Answer Version"
                  value={
                    simplifiedQuest.answers
                      ? simplifiedQuest.answers
                          ?.map((item: simplifiedAnswer) => item.version)
                          .join(" ")
                      : ""
                  }
                />
              )}

              <KeyValueGrid
                label="Answer Linkid"
                value={simplifiedQuest.linkId}
              />
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    );
  };

  const renderSecurityDisplayMessage = (displayMsg: any) => {
    return (
      <Grid>
        <Grid item md={12} paddingLeft={2} paddingBottom={2}>
          {displayMsg}
        </Grid>
      </Grid>
    );
  }

  const getSimplifiedValuesFromQR = (
    items: FhirQuestionnaireResponseMod.Item[]
  ) => {
    const res = items.map((item: FhirQuestionnaireResponseMod.Item) => {
      return getNestedValues(item);
    });

    return res;
  };

  const getQuestionnaireReponseDetails = (
    questRes: FhirQuestionnaireResponseMod.QuestionnaireResponse
  ) => {
    const simplifiedValuesFromQR =
      questRes.item === undefined
        ? undefined
        : getSimplifiedValuesFromQR(questRes.item);

    return (
      <Grid>
        {devMode && (
          <Grid
            className={classes.highlight}
            container
            item
            md={12}
            direction="column"
          >
            <KeyValueGrid
              label="Questionnaire Response ID"
              value={
                questRes.id === undefined
                  ? ""
                  : questRes.resourceType + "/" + questRes.id
              }
            />
            <KeyValueGrid
              label="QuestionnaireResponse.questionnaire"
              value={
                questRes.questionnaire === undefined
                  ? ""
                  : questRes.questionnaire
              }
            />
            <KeyValueGrid
              label="Questionnaire Response Status"
              value={questRes.status}
            />
          </Grid>
        )}

        <Grid container flexDirection="row">
          <Grid container item md={12} paddingLeft={2}>
            <Grid item md={6}>
              <Typography variant="h6" component="h1" paragraph>
                QUESTION
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6" component="h1" paragraph>
                RESPONSE
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6" component="h1" paragraph>
                RESPONSE EXPLANATION
              </Typography>
            </Grid>
          </Grid>

          {simplifiedValuesFromQR?.map((item: JSX.Element) => (
            <Grid item md={12}>
              {item}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container item md={12} flexDirection="row" alignItems="center">
      <Accordion className={classes.root} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {getQuestionnaireReponseSummary(questionnaire, questionnaireResponse)}
        </AccordionSummary>
        {questionnaireResponse &&
        !!questionnaireResponse?.item?.length &&
        !questionnaireResponse?.isSensitive ? (
          getQuestionnaireReponseDetails(questionnaireResponse)
        ) : questionnaireResponse?.isSensitive ? (
          renderSecurityDisplayMessage(
            questionnaireResponse?.meta?.security?.[0]?.display
          )
        ) : (
          <></>
        )}
      </Accordion>
    </Grid>
  );
}
