import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
import Cookies from 'js-cookie';
// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  if(accessToken === 'Not Signed'){
    Cookies.remove('encrypt_user');
    Cookies.remove('access_token');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('encryptUser');
  }
  const decoded = jwtDecode<any>(accessToken);
  const currentTime = Date.now();
  const expirationValidation = decoded['exp'] * 1000 > currentTime && decoded['keycloakExpire'] * 1000 > currentTime
  const validUser = decoded && decoded['userName'] && decoded['role'] && decoded['status']

  if(validUser && expirationValidation){
    return true
  }
  return false
};


const decodedSign = (accessToken: string | null) => {
  if (!accessToken) {
    return false;
  }
  return jwtDecode(accessToken);
}

const setSession = (accessToken: string | null, encryptSign: string | null) => {
  if (accessToken && encryptSign) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('encryptUser', encryptSign);
    Cookies.remove('access_token');
    Cookies.remove('encrypt_user');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('encryptUser');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign, decodedSign };

