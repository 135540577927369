import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddShoppingCartOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { CreateOrderModal } from "pages/patients/PatientModal";
import { Fragment, useEffect, useState } from "react";
import { urlBackNestApp } from "routes/urls";
import {
  dateYearFormat,
  dateYearFormatWithAge,
  getAgeFromString,
} from "utils/datesFormats";
import { formatPatient } from "utils/patientFormat";
import { getBaseUrlNative } from "_helpers";

export interface IPatientCreateOrder {
  uuid: string;
  name?: string;
  birthDate?: string;
  identifier?: any;
}

interface ICreateOrderProps {
  currentPatient: IPatientCreateOrder;
  handleAfterCreation: (orderUuid: string) => void;
  createRequest?: boolean;
  handlerCreateRequest?: (createRequest: boolean) => void;
  useCartButton?: boolean;
  addPatientEngagementChecklistItem?: any;
  hideCreateButton?: boolean;
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function CreateOrder({
  currentPatient,
  handleAfterCreation,
  createRequest,
  handlerCreateRequest,
  useCartButton,
  addPatientEngagementChecklistItem,
  hideCreateButton,
}: ICreateOrderProps) {
  const [openOrderModal, setOpenOrderModal] = useState<any>(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [servicerows, setServicerows] = useState<any>(null);
  const [orderModalforce, setOpenModalforce] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [orderid, setOrderid] = useState(null);
  const [existingorder, setExistingorder] = useState(false);
  const [orderModalPatient, setOrderModalPatient] = useState();

  useEffect(() => {
    if (currentPatient?.uuid === undefined) return;
    getPatient(currentPatient?.uuid);
  }, []);

  useEffect(() => {
    if (createRequest) getRequests(currentPatient.uuid);
  }, [createRequest]);

  const handleOnCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackBar(false);
  };

  const showOrderModal = () => {
    setOpenOrderModal(true);
  };

  const getPatient = async (patientId: string) => {
    // const fetchurl:string = `http://localhost:4000/api/healthcare/patient/${id}`;
    const fetchurl: string =
      (getBaseUrlNative(
        process.env.REACT_APP_ORGANIZATIONS_ENDPOINT,
        `/healthcare/patient/${patientId}`
      ) as string) || "";
    fetch(`${fetchurl}`)
      .then((data) => data.json())
      .then((data) => {
        const formatedPatient: any = formatPatient(data);
        setOrderModalPatient(formatedPatient);
      });
  };

  const getRequests = (patientid: any) => {
    fetch(
      `${urlBackNestApp}/fhirproxy/compositeread/?query=ServiceRequest?subject:Patient._id=${patientid}%26_revinclude=Task:based-on:ServiceRequest%26_include=ServiceRequest:requester%26_revinclude=Task:target%26_include:iterate=Task:owner`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      }
    )
      .then((service) => service.json())
      .then((service) => {
        if (service["total"] > 0) {
          const entries = service.entry;
          const services: any = [];
          const tasks: any = [];
          const requesters: any = [];
          const owners: any = [];
          entries.forEach((a: any) => {
            if (a["resource"]["resourceType"] === "ServiceRequest") {
              services.push({
                service_uri: `ServiceRequest/${a["resource"]["id"]}`,
                service_id: a["resource"]["id"],
                planid:
                  a["resource"]["instantiatesCanonical"] &&
                  a["resource"]["instantiatesCanonical"][0],
                plan_name: "",
                // performer_reference: a['resource']['performer'][0]['reference'],
                service_date: dateYearFormat(a["resource"]["authoredOn"]),
                service_priority: a["resource"]["priority"],
                subject_reference: a["resource"]["subject"]["reference"],
                service_status: a["resource"]["status"],
                requester_reference: a["resource"]["requester"]["reference"],
                service_intent: a["resource"]["intent"],
                service_number: 1,
              });
            } else if (a["resource"]["resourceType"] === "Task") {
              tasks.push({
                task_uri: `Task/${a["resource"]["id"]}`,
                task_id: a["resource"]["id"],
                task_date: dateYearFormat(a["resource"]?.["executionPeriod"]?.["start"]),
                order_number:
                  a["resource"]["identifier"] &&
                  a["resource"]["identifier"][0] &&
                  a["resource"]["identifier"][0]["value"],
                task_status: a["resource"]["status"],
                task_owner: a["resource"]["owner"]["reference"],
                task_basedon: a["resource"]["basedOn"][0]["reference"],
              });
            } else if (
              a["resource"]["resourceType"] === "Organization" ||
              a["resource"]["resourceType"] === "Practitioner"
            ) {
              requesters.push({
                requester_uri: `${a["resource"]["resourceType"]}/${a["resource"]["id"]}`,
                requester_name:
                  a["resource"]["resourceType"] === "Organization"
                    ? a["resource"]["name"]
                    : `${a["resource"]["name"][0]["given"].join(" ")} ${
                        a["resource"]["name"][0]["family"]
                      }`,
              });
            } else {
              owners.push({
                owner_uri: `${a["resource"]["resourceType"]}/${a["resource"]["id"]}`,
                owner_name: `${a["resource"]["name"][0]["given"].join(" ")} ${
                  a["resource"]["name"][0]["family"]
                }`,
              });
            }
          });
          const mergebykey = (x: any, y: any, key1: any, key2: any) =>
            x.map((itm: any) => ({
              ...y.find((item: any) => item[key2] === itm[key1]),
              ...itm,
            }));
          const task_merged_owners = mergebykey(
            tasks,
            owners,
            "task_owner",
            "owner_uri"
          );
          const task_merged_services = mergebykey(
            task_merged_owners,
            services,
            "task_basedon",
            "service_uri"
          );
          const task_merged_requesters = mergebykey(
            task_merged_services,
            requesters,
            "requester_reference",
            "requester_uri"
          );
          const map_service_task: any = {};
          for (const entity of task_merged_requesters) {
            if (!map_service_task[entity.service_uri]) {
              map_service_task[entity.service_uri] = {
                plan_name: entity.planname,
                service_date: entity.service_date,
                service_priority: entity.service_priority,
                service_status: entity.service_status,
                service_intent: entity.service_intent,
                service_number: entity.order_number,
                requester_name: entity.requester_name,
                service_id: entity.service_id,
                orderid: entity.service_id,
                tasks: [
                  {
                    owner_name: entity.owner_name,
                    task_date: entity.task_date,
                    task_status: entity.task_status,
                    task_id: entity.task_id,
                  },
                ],
              };
            } else {
              map_service_task[entity.service_uri].tasks.push({
                owner_name: entity.owner_name,
                task_date: entity.task_date,
                task_status: entity.task_status,
                task_id: entity.task_id,
              });
            }
          }
          const mapped_task_services = Object.values(map_service_task);
          console.log(mapped_task_services);
          setServicerows(mapped_task_services);
          setAnchorEl(null);
          setOpenModalforce(true);
        } else {
          setAnchorEl(null);
          setOpenOrderModal(true);
        }
      });
  };

  const create_new_request = () => {
    fetch(`${urlBackNestApp}/healthcare/patient/userorg/getorg`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
    })
      .then((userorg) => userorg.json())
      .then((userorg) => {
        const { name, fhirUri } = userorg;
        if (name !== "" && fhirUri !== "") {
          setOrderid(null);
          setExistingorder(false);
          setOpenModalforce(false);
          setOpenOrderModal(true);
        } else {
          setOrderid(null);
          setExistingorder(false);
          setOpenModalforce(false);
          setOpenOrderModal(false);
          setSnackBarMsg("Operation not allowed, missing organization");
          setShowSnackBar(true);
        }
      });
  };

  function servicedata(
    plan_name: string,
    service_date: string,
    service_priority: string,
    service_status: string,
    service_intent: string,
    service_number: string,
    requester_name: string,
    tasks: []
  ) {
    return {
      plan_name,
      service_date,
      service_priority,
      service_status,
      service_intent,
      service_number,
      requester_name,
      tasks,
    };
  }

  function ServiceRow(props: { row: ReturnType<typeof servicedata> }) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const newtaskclick = (row: any) => {
      fetch(`${urlBackNestApp}/healthcare/patient/userorg/getorg`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
        .then((userorg) => userorg.json())
        .then((userorg) => {
          const { name, fhirUri } = userorg;
          if (name !== "" && fhirUri !== "") {
            setOrderid(row.orderid);
            setExistingorder(true);
            setOpenModalforce(false);
            setOpenOrderModal(true);
          } else {
            setOrderid(row.orderid);
            setExistingorder(false);
            setOpenModalforce(false);
            setOpenOrderModal(false);
          }
        });
    };
    return (
      <Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.service_number}
          </TableCell>
          <TableCell align="right">{row.service_status}</TableCell>
          <TableCell align="right">{row.plan_name}</TableCell>
          <TableCell align="right">{row.requester_name}</TableCell>
          <TableCell align="right">{row.service_date}</TableCell>
          {row.service_status === "active" ? (
            <TableCell align="right">
              <Button
                variant="outlined"
                disableElevation
                onClick={() => newtaskclick(row)}
              >
                New Task
              </Button>
            </TableCell>
          ) : null}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Task Details
                </Typography>
                <Table size="small" aria-label="tasks">
                  <TableHead>
                    <TableRow>
                      <TableCell>Owner</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.tasks.map((taskrow: any) => (
                      <TableRow key={taskrow.task_id}>
                        <TableCell component="th" scope="row">
                          {taskrow.owner_name}
                        </TableCell>
                        <TableCell>{taskrow.task_date}</TableCell>
                        <TableCell>{taskrow.task_status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  const getButton = (useCartButton?: boolean) => {
    if (useCartButton) {
      return (
        <Tooltip title="Create Request">
          <IconButton
            onClick={() => getRequests(currentPatient.uuid)}
            style={{ marginLeft: 16 }}
          >
            <AddShoppingCartOutlined />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Button
        variant="contained"
        onClick={() => getRequests(currentPatient.uuid)}
      >
        Create Request
      </Button>
    );
  };

  return (
    <div>
      {/* <Button
        variant="contained"
        onClick={() => getRequests(currentPatient.uuid)}
      >
        Create Request
      </Button> */}

      {!hideCreateButton && getButton(useCartButton)}

      <CreateOrderModal
        orderModalOpen={openOrderModal}
        setOrderModalOpen={setOpenOrderModal}
        patient={orderModalPatient}
        orderid={orderid}
        snackerror={setShowSnackBar}
        snackerrormsg={setSnackBarMsg}
        existingorder={existingorder}
        handleAfterCreation={handleAfterCreation}
        addPatientEngagementChecklistItem={addPatientEngagementChecklistItem}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => {
          setOpenModalforce(false);
          handlerCreateRequest(false);
        }}
        open={orderModalforce}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "center", background: "#309591" }}>
          <Typography
            key="3"
            variant="h4"
            color="text.primary"
            style={{ color: "white", marginBottom: "10px" }}
          >
            Order Found
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={3}>
            <Grid item md={12} container direction="column">
              <Typography key="3" color="text.primary">
                <h3>Member Details</h3>
              </Typography>
            </Grid>
            <Grid item md={12} container direction="column">
              <Typography key="3" color="text.primary">
                <b>Member Name</b>
              </Typography>
              <Typography key="3" style={{ color: "#919eab" }}>
                {orderModalPatient &&
                  orderModalPatient["name"]["given"] +
                    orderModalPatient["name"]["family"]}
              </Typography>
            </Grid>
            <Grid item md={4} container direction="column">
              <Typography key="3" color="text.primary">
                <b>Date of Birth</b>
              </Typography>
              <Typography key="3" style={{ color: "#919eab" }}>
                {orderModalPatient &&
                  dateYearFormatWithAge(orderModalPatient["birthDate"])}
              </Typography>
            </Grid>
            <Grid item md={4} container direction="column">
              <Typography key="3" color="text.primary">
                <b>Age</b>
              </Typography>
              <Typography key="3" style={{ color: "#919eab" }}>
                {orderModalPatient &&
                  orderModalPatient["birthDate"] &&
                  getAgeFromString(orderModalPatient["birthDate"])}
              </Typography>
            </Grid>
            <Grid item md={4} container direction="column">
              <Typography key="3" color="text.primary">
                <b>MRN</b>
              </Typography>
              <Typography key="3" style={{ color: "#919eab" }}>
                {orderModalPatient && orderModalPatient["mrn"]}
              </Typography>
            </Grid>
            <Grid item md={12} container direction="column">
              <Typography key="3" color="text.primary">
                <h3>Service Request Details</h3>
              </Typography>
            </Grid>
            <Grid item md={12} container direction="column">
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Number</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Plan Definition</TableCell>
                      <TableCell align="right">Requester</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Add Task</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicerows &&
                      servicerows.map((row: any) => (
                        <ServiceRow key={row.service_id} row={row} />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOrderid(null);
              setExistingorder(false);
              setOpenModalforce(false);
            }}
            color="error"
            type="reset"
          >
            Cancel
          </Button>
          <Button color="primary" onClick={create_new_request}>
            Create Order
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackBar}
        onClose={handleOnCloseSnackBar}
        key={"topcenter"}
        children={
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="warning"
            onClose={handleOnCloseSnackBar}
          >
            {snackBarMsg}
          </MuiAlert>
        }
      />
    </div>
  );
}
