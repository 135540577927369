import React, { useEffect, useState } from "react";
import { AddCircleOutlineOutlined, Edit } from "@material-ui/icons";
import {
  Avatar,
  Snackbar,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  Drawer,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";

import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { urlBackNestApp } from "routes/urls";

export default function ConfirmCMOptInItem({
  isOpen,
  handlerIsOpen,
  addPatientEngagementChecklistItem,
}: any) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(isOpen);
  const [optInType, setOptInType] = useState([]);
  // const [data, setData] = useState({});
  const [optInDecisionCatalog, setOptInDecisionCatalog] = useState([]);

  useEffect(() => {
    setConfirmModalOpen(isOpen);
  }, [isOpen]);

  const schema = Yup.object().shape({
    opt_in_decision: Yup.string().required("Opt-In Decision is required"),
    type: Yup.string().required("Type is required"),
    notes: Yup.string().required("Notes are required"),
    dispose: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      opt_in_decision: "",
      type: "",
      notes: "",
      dispose: false,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  // const handleOnChange = (e: any) =>
  //   setData({ ...data, [e.target.name]: e.target.value });

  const showConfirmModal = () => {
    console.log("showConfirmModal");
    setConfirmModalOpen((prev) => !prev);
  };

  const getOptInType = async () => {
    const optInTypeCode = "OPT_IN_TYPES";
    axios
      .get(`${urlBackNestApp}/catalog/${optInTypeCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInType(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const getOptInDecisions = async () => {
    const optInDecisionCode = "OPT_IN_DECISIONS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInDecisionCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInDecisionCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  useEffect(() => {
    getOptInType();
    getOptInDecisions();
  }, []);

  const handleSubmitData = (values: any) => {
    console.log("values: ", values);

    const valuesToSubmit = { ...values };
    delete valuesToSubmit.dispose;

    try {
      console.log(
        "PatientEngamement ConfirmCMOptInItem valuesToSubmit: ",
        valuesToSubmit
      );
      addPatientEngagementChecklistItem(
        "CONFIRM_CM_OPTIN_ITEM",
        "CM OptIn Confirmed",
        valuesToSubmit,
        false,
        values.dispose
      );
      handlerIsOpen(false);
      setConfirmModalOpen(false);
    } catch (error) {
      console.log("failed confirming CM Opt In");
    }
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { width: "60%" },
        }}
        anchor="left"
        open={confirmModalOpen}
        onClose={() => {
          setConfirmModalOpen(false);
          handlerIsOpen(false);
        }}
      >
        <List component="nav">
          <ListItem>
            <Grid container>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSubmitData(values);
                  }}
                >
                  Confirm CM Opt-In
                </Button>
                <Button
                  onClick={() => {
                    setConfirmModalOpen(false);
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </ListItem>
        </List>
        <Divider sx={{ marginBottom: 2 }} />

        <FormikProvider value={formik}>
          <Form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
            // onChange={handleOnChange}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={0}
              xs={10}
              ml={2}
              mr={2}
            >
              <Grid container item direction="row" alignItems="center">
                <Grid container item xs={3} justifyContent="start">
                  <InputLabel htmlFor="opt_in_decision">
                    Opt-In Decision
                  </InputLabel>
                </Grid>
                <Grid container item xs={9} justifyContent="start">
                  <TextField
                    fullWidth
                    id="opt_in_decision"
                    select
                    variant="standard"
                    label="Opt-In Desicion"
                    {...getFieldProps("opt_in_decision")}
                    // onChange={formik.handleChange}
                    error={Boolean(
                      touched.opt_in_decision && errors.opt_in_decision
                    )}
                    helperText={
                      touched.opt_in_decision && errors.opt_in_decision
                        ? errors.opt_in_decision
                        : " "
                    }
                    value={formik.values.opt_in_decision}
                  >
                    <MenuItem key={""} value={""} disabled></MenuItem>
                    {optInDecisionCatalog.map((opt_in_decision: any) => (
                      <MenuItem
                        key={opt_in_decision.shortDisplayName}
                        value={opt_in_decision.shortDisplayName}
                      >
                        {opt_in_decision.shortDisplayName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container item direction="row" alignItems="center">
                <Grid container item xs={3} justifyContent="start">
                  <InputLabel htmlFor="confirm_cm_type">Type</InputLabel>
                </Grid>
                <Grid container item xs={9} justifyContent="start">
                  <TextField
                    fullWidth
                    id="confirm_cm_type"
                    select
                    variant="standard"
                    label="Type"
                    {...getFieldProps("type")}
                    // onChange={formik.handleChange}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type ? errors.type : " "}
                    value={formik.values.type}
                  >
                    <MenuItem key={""} value={""} disabled></MenuItem>
                    {optInType.map((type: any) => (
                      <MenuItem
                        key={type.shortDisplayName}
                        value={type.shortDisplayName}
                      >
                        {type.shortDisplayName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container item direction="row" alignItems="center">
                <Grid container item xs={3} justifyContent="start">
                  <InputLabel htmlFor="confirm_cm_notes">Notes</InputLabel>
                </Grid>
                <Grid container item xs={9} justifyContent="start">
                  <TextField
                    fullWidth
                    id="confirm_cm_notes"
                    variant="standard"
                    label="Type Notes"
                    multiline
                    minRows={3}
                    {...getFieldProps("notes")}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={
                      touched.notes && errors.notes ? errors.notes : " "
                    }
                  />
                </Grid>
              </Grid>

              {formik.values.opt_in_decision === "Deny" && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  minHeight={60}
                >
                  <Grid container item xs={4}>
                    <Typography>Close engagement box</Typography>
                  </Grid>

                  <Grid container item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...getFieldProps("dispose")}
                          checked={formik.values.dispose}
                        />
                      }
                      label=""
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </FormikProvider>
      </Drawer>
    </>
  );
}
