import { getEnvVar } from "utils/env";

export const PROVIDER_DIRECTORY_BASE_URL = getEnvVar(
  "PROVIDER_DIRECTORY_BASE_URL"
);

export const PCP_ROLE_CODE = getEnvVar("PCP_ROLE_CODE");

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE = getEnvVar(
  "PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE"
);

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM = getEnvVar(
  "PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM"
);

export const PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM = getEnvVar(
  "PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM"
);

export const PH_META_TAG_PROVIDER_SOURCE_CODE = getEnvVar(
  "PH_META_TAG_PROVIDER_SOURCE_CODE"
);

export const PH_META_TAG_CREATED_ON_CODE = getEnvVar(
  "PH_META_TAG_CREATED_ON_CODE"
);

export const PH_META_TAG_CREATED_BY_CODE = getEnvVar(
  "PH_META_TAG_CREATED_BY_CODE"
);

export const PH_META_TAG_CODE_SYSTEM_URL = getEnvVar(
  "PH_META_TAG_CODE_SYSTEM_URL"
);

export const PROVIDER_CLASSIFICATION_CODING_SYSTEM = getEnvVar(
  "PROVIDER_CLASSIFICATION_CODING_SYSTEM"
);

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM = getEnvVar(
  "PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_SYSTEM"
);

export const PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM = getEnvVar(
  "PROVIDER_LICENSE_VERIFICATION_IDENTIFIER_CODING_SYSTEM"
);

export const PROVIDER_LICENSE_IDENTIFIER_CODING_CODE = getEnvVar(
  "PROVIDER_LICENSE_IDENTIFIER_CODING_CODE"
);

export const TERMINOLOGY_SERVER_BASE_URL = getEnvVar(
  "TERMINOLOGY_SERVER_BASE_URL"
);

export const PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE = getEnvVar(
  "PROVIDER_CLASSIFICATION_IDENTIFIER_CODING_CODE"
);
