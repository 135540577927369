import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddCircleOutlineOutlined, Edit } from "@material-ui/icons";
import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { urlBackNestApp } from "routes/urls";
import * as Yup from "yup";
import agent from "../../api/agent";

interface ConfirmNatOptInProps {
  patientEngagement: any;
  isOpen: boolean;
  handlerIsOpen: Function;
  checklist: any;
  workflow?: any;
  checklistHandler?: any;
  addPatientEngagementChecklistItem: Function;
}

export default function ConfirmNatOptIn({
  patientEngagement,
  isOpen,
  handlerIsOpen,
  checklist,
  checklistHandler,
  addPatientEngagementChecklistItem,
}: ConfirmNatOptInProps) {
  const [
    confirmNatOptInModalOpen,
    setConfirmNatOptInModalOpen,
  ] = useState<boolean>(isOpen);
  const [optInMethodCatalog, setOptInMethodCatalog] = useState([]);
  const [optInDecisionCatalog, setOptInDecisionCatalog] = useState([]);

  const confirmNatOptInSchema = Yup.object().shape({
    opt_in_decision: Yup.string().required("Opt-In Decision is required"),
    method: Yup.string().required("Method is required"),
    notes: Yup.string().required("Notes are required"),
    dispose: Yup.boolean(),
  });

  const initialValues = {
    opt_in_decision: "",
    method: "",
    notes: "",
    dispose: false,
  };

  const handleSubmit = async (values: any) => {
    console.log("values: ", values);

    const valuesToSubmit = { ...values };
    delete valuesToSubmit.dispose;

    try {
      console.log(
        "PatientEngamement ConfirmNATOptInItem valuesToSubmit: ",
        valuesToSubmit
      );
      addPatientEngagementChecklistItem(
        "CONFIRM_NAT_OPTIN_ITEM",
        "Nat OptIn confirmed",
        valuesToSubmit,
        false,
        values.dispose
      );
      handlerIsOpen(false);
      setConfirmNatOptInModalOpen(false);
    } catch (error) {
      console.log("failed confirming NAT Opt In");
    }
  };

  useEffect(() => {
    setConfirmNatOptInModalOpen(isOpen);
  }, [isOpen]);

  const getOptInMethods = async () => {
    const optInMethodCode = "OPT_IN_METHODS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInMethodCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInMethodCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const getOptInDecisions = async () => {
    const optInDecisionCode = "OPT_IN_DECISIONS";
    axios
      .get(`${urlBackNestApp}/catalog/${optInDecisionCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setOptInDecisionCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  // useEffect(() => {
  //   if (optInDecisionCatalog && optInDecisionCatalog.length > 0) {
  //     console.log("DEBUG PE optInDecisionCatalog: ", optInDecisionCatalog);
  //   }
  // }, [optInDecisionCatalog]);

  useEffect(() => {
    getOptInMethods();
    getOptInDecisions();
  }, []);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={confirmNatOptInSchema}
        onSubmit={handleSubmit}
      >
        {(props: any) => {
          return (
            <Form id="confirmNatOptInForm" autoComplete="off">
              <List component="nav">
                <ListItem>
                  <Grid container justifyContent="space-between">
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={props.isSubmitting}
                      >
                        Confirm NAT Opt-In
                      </Button>
                      <Button
                        onClick={() => {
                          setConfirmNatOptInModalOpen(false);
                          handlerIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem>
                  <Grid container direction="column" marginTop={2} width={800}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      minHeight={60}
                    >
                      <Grid container item xs={4}>
                        <Typography>Opt-In Decision</Typography>
                      </Grid>

                      <Grid container item xs={8}>
                        <TextField
                          id="opt_in_decision"
                          fullWidth
                          placeholder="Select Opt-In Decision"
                          SelectProps={{
                            native: true,
                          }}
                          {...props.getFieldProps("opt_in_decision")}
                          error={Boolean(
                            props.touched.opt_in_decision &&
                              props.errors.opt_in_decision
                          )}
                          helperText={
                            props.touched.opt_in_decision &&
                            props.errors.opt_in_decision
                          }
                          select
                        >
                          <option value="" disabled></option>
                          {optInDecisionCatalog.map((decision: any) => (
                            <option value={decision.shortDisplayName}>
                              {decision.shortDisplayName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      minHeight={60}
                    >
                      <Grid container item xs={4}>
                        <Typography>Method</Typography>
                      </Grid>

                      <Grid container item xs={8}>
                        <TextField
                          id="method"
                          fullWidth
                          placeholder="Select Method"
                          SelectProps={{
                            native: true,
                          }}
                          {...props.getFieldProps("method")}
                          error={Boolean(
                            props.touched.method && props.errors.method
                          )}
                          helperText={
                            props.touched.method && props.errors.method
                          }
                          select
                        >
                          <option value="" disabled></option>
                          {optInMethodCatalog.map((method: any) => (
                            <option value={method.shortDisplayName}>
                              {method.shortDisplayName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      minHeight={60}
                    >
                      <Grid container item xs={4}>
                        <Typography>Notes</Typography>
                      </Grid>

                      <Grid container item xs={8}>
                        <TextField
                          id="notes"
                          placeholder="Type Notes"
                          fullWidth
                          multiline
                          minRows={3}
                          {...props.getFieldProps("notes")}
                          error={Boolean(
                            props.touched.notes && props.errors.notes
                          )}
                          helperText={props.touched.notes && props.errors.notes}
                        />
                      </Grid>
                    </Grid>

                    {props.values.opt_in_decision === "Deny" && (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={4}>
                          <Typography>Close engagement box</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...props.getFieldProps("dispose")}
                                checked={props.values.dispose}
                              />
                            }
                            label=""
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </ListItem>
              </List>
            </Form>
          );
        }}
      </Formik>
    </Drawer>
  );
}
