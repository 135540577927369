import { useState, useEffect } from "react";

import { FhirPlanDefinitionMod } from "models/fhirPlanDefinition";
import { FhirOrganizationMod } from "models/organization";
import { FhirPatientMod } from "models/patient";
import { FhirPractitionerMod } from "models/practitioner";
import { FhirServiceRequestMod } from "models/serviceRequest";
import { FhirTaskMod } from "models/task";
import {
  getResourceRef,
  getSplitReference,
  getTaskNumberFromIdentifiers,
} from "utils/fhirUtils";
import agent from "../api/agent";

// ----------------------------------------------------------------------

export default function useServiceRequest() {
  const [serviceRequestId, setServiceRequestId] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const [
    fhirServiceRequest,
    setFhirServiceRequest,
  ] = useState<FhirServiceRequestMod.ServiceRequest>();
  const [fhirTasks, setFhirTasks] = useState<FhirTaskMod.Task[]>();
  const [
    fhirPlanDefinition,
    setFhirPlanDefinition,
  ] = useState<FhirPlanDefinitionMod.PlanDefinition>();
  const [fhirPatient, setFhirPatient] = useState<FhirPatientMod.Patient>();
  const [
    fhirGeneralPractitioner,
    setFhirGeneralPractitioner,
  ] = useState<FhirPractitionerMod.Practitioner>();
  const [
    fhirPractitioner,
    setFhirPractitioner,
  ] = useState<FhirPractitionerMod.Practitioner>();
  const [
    fhirOrganization,
    setFhirOrganization,
  ] = useState<FhirOrganizationMod.Organization>();
  const [msgLoading, setMsgLoading] = useState<string>("Loading...");

  const _setFhirPatient = async (uuid: string) => {
    const response = await agent.FhirPatient.details(uuid);
    const userOrg = await agent.FhirPatient.detailsWithOrg(uuid);

    const organizationName =
      userOrg?.entry?.filter(
        (item: any) => item.resource.resourceType === "Organization"
      )[0]["resource"]["name"] || "";
    const responseWithOrgName = Object.assign(response, {
      organizationname: organizationName,
    });

    setFhirPatient(responseWithOrgName);
  };

  const _setFhirGeneralPractitioner = async (uuid: string) => {
    const response = await agent.FhirPractitioner.details(uuid);
    setFhirGeneralPractitioner(response);
  };

  const _setFhirPractitioner = async (uuid: string) => {
    const response = await agent.FhirPractitioner.details(uuid);
    setFhirPractitioner(response);
  };

  const _setFhirOrganization = async (uuid: string) => {
    const response = await agent.FhirOrganization.details(uuid);
    setFhirOrganization(response);
  };

  const _setFhirServiceRequest = async (uuid: string) => {
    const response = await agent.FhirServiceRequest.detailsWithTasks(uuid);
    setFhirServiceRequest(response.serviceRequest);
    setFhirTasks(response.tasks);
  };

  const _setFhirPlanDefinition = async (uuid: string) => {
    const response = await agent.FhirPlanDefinition.details(uuid);
    setFhirPlanDefinition(response);
  };

  useEffect(() => {
    if (fhirServiceRequest) {
      if (fhirServiceRequest.subject.reference !== undefined) {
        const patientSplitRef = getSplitReference(
          fhirServiceRequest.subject.reference
        );
        _setFhirPatient(patientSplitRef.uuid);
      }

      if (fhirServiceRequest.requester?.reference !== undefined) {
        const isPractitioner = checkIfPractitioner(
          fhirServiceRequest.requester.reference
        );

        const splitRef = getSplitReference(
          fhirServiceRequest.requester.reference
        );

        if (isPractitioner) {
          _setFhirPractitioner(splitRef.uuid);
        } else {
          _setFhirOrganization(splitRef.uuid);
        }
      }

      if (fhirServiceRequest.instantiatesCanonical !== undefined) {
        const planDefinitionUuids: string[] = [];

        fhirServiceRequest.instantiatesCanonical.forEach((item: string) => {
          const resRef = getResourceRef(item);

          if (resRef !== undefined) {
            const splitRef = getSplitReference(resRef);

            if (splitRef.resourceType === "PlanDefinition")
              planDefinitionUuids.push(splitRef.uuid);
          }
        });

        if (planDefinitionUuids.length > 0)
          _setFhirPlanDefinition(planDefinitionUuids[0]);
      }
    }
  }, [fhirServiceRequest]);

  useEffect(() => {
    if (
      fhirPatient &&
      fhirPatient.generalPractitioner?.[0].reference !== undefined
    ) {
      const splitRef = getSplitReference(
        fhirPatient.generalPractitioner[0].reference
      );
      _setFhirGeneralPractitioner(splitRef.uuid);
    }
  }, [fhirPatient]);

  useEffect(() => {
    if (serviceRequestId) {
      _setFhirServiceRequest(serviceRequestId);
    }
  }, [serviceRequestId]);

  const checkIfPractitioner = (value: string): boolean => {
    const splitRef = getSplitReference(value);
    return splitRef.resourceType === "Practitioner";
  };

  return {
    serviceRequestId,
    setServiceRequestId,
    taskId,
    setTaskId,
    msgLoading,
    setMsgLoading,
    fhirServiceRequest,
    fhirPlanDefinition,
    fhirOrganization,
    fhirPractitioner,
    fhirPatient,
    fhirGeneralPractitioner,
    fhirTasks,
    _setFhirServiceRequest,
  };
}
