import { urlBackNestApp } from "routes/urls";
import { Button, Grid } from "@material-ui/core";
import KeyValueGrid from "components/KeyValueGrid";
import KeyValuePaper from "components/KeyValuePaper";
import { ITaskRowInfo } from "../../@types/patientEngagement";

const TaskRow = ({ taskRowInfo }: ITaskRowInfo) => {
  const answerNat = (taskNumber: string) => {
    const url = `${urlBackNestApp}/redirect/request?requestNo=${taskNumber}&request-no=${taskNumber}&id=${taskRowInfo.userName}`;
    window.open(url);
    // const link = document.createElement("a");
    // link.href = `${urlBackNestApp}/redirect/request?requestNo=${taskNumber}&request-no=${taskNumber}&id=${taskRowInfo.userName}`;
    // document.body.appendChild(link);
    // link.click();
  };

  console.log("taskRow: ", taskRowInfo);

  return (
    <Grid container item md={12} key={taskRowInfo.id}>
      <KeyValuePaper>
        <KeyValueGrid label="Task No." value={taskRowInfo.taskNumber} />
        <KeyValueGrid label="Name" value={taskRowInfo.name} />
        <KeyValueGrid label="Type" value={taskRowInfo.type} />
        <Grid
          container
          item
          md={4}
          key={taskRowInfo.id}
          alignContent="center"
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            onClick={() => {
              answerNat(taskRowInfo.taskNumber);
            }}
          >
            Open
          </Button>
        </Grid>
      </KeyValuePaper>
    </Grid>
  );
};

export default TaskRow;
