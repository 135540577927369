export const TRANSLATIONS_EN = {
  patient_engagement: {
    workflow: {
      dispositionBy: 'Disposition By',
    },
    complementary_info: {
      title: 'Complementary Info',
      ASSIGN_OWNER_ITEM: 'Assign Owner',
      CONTACT_ITEM: 'Contact',
      CONFIRM_MEMBER_INFO_ITEM: 'Confirm Member Info',
      CONFIRM_GUARANTOR_INFO_ITEM: 'Confirm Guarantor Info',
      CONFIRM_NAT_OPTIN_ITEM: 'Confirm NAT OptIn',
      SCHEDULE_NAT_SESSION_ITEM: 'Schedule NAT Session',
      INITIATE_NAT_REQUEST_ITEM: 'Inititate NAT Request',
      COMPLETE_NAT_INTERVIEW_ITEM: 'Complete NAT Interview',
      REVIEW_NAT_REPORT_ITEM: 'Review NAT Report',
      CONFIRM_CM_OPTIN_ITEM: 'Confirm CM OptIn',
      validatedOn: 'Validated On',
      validatedBy: 'Validated By',
      opt_in_decision: 'Optin Decision',
      method: 'Method',
      notes: 'Notes',
      dateHour: 'Date Hour',
      type: 'Type',
    }
  }
};
