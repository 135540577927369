import useAuth from "hooks/useAuth";
import Cookies from "js-cookie";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

export default function RedirectGuard({ children }: { children: ReactNode }) {
    const navigate = useNavigate();
    const path = useLocation();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        const redirectURLCookie = Cookies.get('redirectURLCookie');
        const redirectStorage = localStorage.getItem('redirectURL') || '/';

        if (redirectURLCookie === undefined) return;

        const redirectPath = JSON.parse(redirectURLCookie);

        if (path.pathname === redirectPath.pathname && isAuthenticated) {
            Cookies.remove('redirectURLCookie');
            return;
        }

        navigate(redirectStorage, {
            replace: false,
        })

    })
    return <>{children}</>
}