// material
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 149, height: 47, ...sx }}>
      <img src="/static/brand/logo.png" alt="Logo" />
    </Box>
  );
}
