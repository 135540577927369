import { Button, Divider, Drawer, Grid, List, ListItem, MenuItem, Select, Stack, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { urlBackNestApp } from 'routes/urls';
import * as Yup from 'yup';
import agent from "../../api/agent";

interface ScheduleNatSessionProps {
  patientEngagement: any,
  isOpen: boolean,
  handlerIsOpen: Function,
  checklist: any,
  workflow?: any,
  checklistHandler?: any,
}

export default function ScheduleNatSession({ patientEngagement, isOpen, handlerIsOpen, checklist, checklistHandler }: ScheduleNatSessionProps) {
  const [scheduleNatSessionModalOpen, setScheduleNatSessionModalOpen] = useState<boolean>(isOpen);
  const [natSessionTypeCatalog, setNatSessionTypeCatalog] = useState([]);
  const [dateTime, setDateTime] = useState<Date | null>(
    new Date(),
  );

  const scheduleNatSessionSchema = Yup.object().shape({
    dateHour: Yup.date().required('The date and hour is required').min(new Date(), 'Date cannot be in the past'),
    type: Yup.string().required('Type is required')
  });

  const initialValues = {
    dateHour: dateTime,
    type: ''
  };

  useEffect(() => {
    setScheduleNatSessionModalOpen(isOpen);
  }, [isOpen]);

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      checklistItemId: checklist.id,
      workflowStageChecklistRef: null,
      patientEngagementRef: patientEngagement.id,
      code:'SCHEDULE_NAT_SESSION_ITEM',
      message:'Nat sesssion scheduled',
      meta: values
    };
    agent.PatientEngagement.checkitem(data)
      .then((response) => {
        handlerIsOpen(false);
        setScheduleNatSessionModalOpen(false);
        if (checklistHandler) checklistHandler();
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const getNatSessionType = async () => {
    const natSessionTypeCode = 'NAT_SESSION_TYPES';
    axios.get(`${urlBackNestApp}/catalog/${natSessionTypeCode}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('encryptUser')}`
      }
    })
    .then((response) => {
      setNatSessionTypeCatalog(response.data);
    })
    .catch((reason) => {
      console.log(reason);
    });
  };

  useEffect(() => {
    getNatSessionType();
  }, []);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false)
      }}>
      <Formik
        initialValues={initialValues}
        validationSchema={scheduleNatSessionSchema}
        onSubmit={handleSubmit}>
        {
          (props: any) => {
            return (
              <Form id="scheduleNatSessionForm" autoComplete="off">
                <List component="nav">
                  <ListItem>
                    <Grid
                      container
                      justifyContent="space-between">
                      <Stack direction="row" spacing={2}>
                        <Button variant="contained" type="submit" disabled={props.isSubmitting}>Schedule NAT Session</Button>
                        <Button onClick={() => {
                          setScheduleNatSessionModalOpen(false);
                          handlerIsOpen(false);
                        }}>Cancel</Button>
                      </Stack>
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid
                      container
                      direction="column"
                      marginTop={2}
                      width={800}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}>
                        <Grid
                          container
                          item
                          xs={4}>
                          <Typography>
                            Date and Hour
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={8}>
                          <TextField
                            id="dateHour"
                            type="datetime-local"
                            fullWidth
                            {...props.getFieldProps('dateHour')}
                            error={Boolean(props.touched.dateHour && props.errors.dateHour)}
                            helperText={props.touched.dateHour && props.errors.dateHour} />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}>
                        <Grid
                          container
                          item
                          xs={4}>
                          <Typography>
                            Type
                          </Typography>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={8}>
                          <TextField
                            id="type"
                            fullWidth
                            placeholder="Select Type"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            {...props.getFieldProps('type')}
                            error={Boolean(props.touched.type && props.errors.type)}
                            helperText={props.touched.type && props.errors.type}>
                            <option value="" disabled></option>
                            {
                              natSessionTypeCatalog.map((type: any) => <option value={type.shortDisplayName}>{type.shortDisplayName}</option>)
                            }
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Form>
            );
          }
        }
      </Formik>
    </Drawer>
  );
}