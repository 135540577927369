import { withStyles } from "@material-ui/core/styles";

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  "@global": {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    html: {
      width: "100%",
      height: "100%",
      "-ms-text-size-adjust": "100%",
      "-webkit-overflow-scrolling": "touch",
    },
    body: {
      "@media print": {
        "color-adjust": "exact",
        "-webkit-print-color-adjust": "exact",
      },
      width: "100%",
      height: "100%",
    },
    "#root": {
      width: "100%",
      height: "100%",
    },
    input: {
      "&[type=number]": {
        MozAppearance: "textfield",
        "&::-webkit-outer-spin-button": { margin: 0, WebkitAppearance: "none" },
        "&::-webkit-inner-spin-button": { margin: 0, WebkitAppearance: "none" },
      },
    },
    textarea: {
      "&::-webkit-input-placeholder": { color: theme.palette.text.disabled },
      "&::-moz-placeholder": { opacity: 1, color: theme.palette.text.disabled },
      "&:-ms-input-placeholder": { color: theme.palette.text.disabled },
      "&::placeholder": { color: theme.palette.text.disabled },
    },
    a: { color: theme.palette.primary.main },
    img: { display: "block", maxWidth: "100%" },

    // Lazy Load Img
    ".blur-up": {
      WebkitFilter: "blur(5px)",
      filter: "blur(5px)",
      transition: "filter 400ms, -webkit-filter 400ms",
    },
    ".blur-up.lazyloaded ": {
      WebkitFilter: "blur(0)",
      filter: "blur(0)",
    },
    ".MuiBreadcrumbs-separator": {
      color: "#000000",
      fontSize: "24px",
    },
    header: {
      "@media print": {
        display: "none !important",
      },
    },
    ".njinck-sidebar-container": {
      "@media print": {
        display: "none !important",
      },
    },
    ".main-content-container > div > header": {
      "@media print": {
        display: "none !important",
      },
    },
    ".measure-report-background-indicator-indeterminate-risk": {
      backgroundColor: "#9c27b0",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-indeterminate-risk": {
      backgroundColor: "#9c27b0",
      color: "#ffffff",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-none": {
      backgroundColor: "#f57c00",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-none": {
      backgroundColor: "#f57c00",
      color: "#000000",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-low-risk": {
      backgroundColor: "#229a16",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-low-risk": {
      backgroundColor: "#3dab2b",
      color: "#ffffff",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-medium-risk": {
      backgroundColor: "#ffc107",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-medium-risk": {
      backgroundColor: "#ffcb2f",
      color: "#000000",
      borderRadius: 4,
    },
    ".measure-report-background-indicator-high-risk": {
      backgroundColor: "#ff4842",
      minHeight: "40px",
      borderRadius: 4,
    },
    ".measure-report-indicator-high-risk": {
      backgroundColor: "#ff6860",
      color: "#ffffff",
      borderRadius: 4,
    },

    ".MuiCollapse-root": {
      "@media print": {
        height: "auto !important",
        overflow: "visible !important",
        visibility: "visible !important"
      },
    },
    ".BOLD": {
      color: "red",
      fontWeight: "bold"
    }
  },
}))(() => null);

export default GlobalStyles;
