import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

interface KeyValuePaperProps {
  title: string;
  onClickTitle?: React.MouseEventHandler<HTMLHeadingElement>;
  children?: React.ReactNode;
}

export default function KeyValuePaper({
  title,
  onClickTitle,
  children,
}: KeyValuePaperProps) {
  return (
    <Paper elevation={3} style={{ width: "100%" }}>
      <Grid container direction="column" padding={1}>
        <Grid
          container
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          justifyContent="center"
          style={{ marginTop: "8px" }}
        >
          <Typography
            variant="h3"
            component="h1"
            paragraph
            align="center"
            style={{ cursor: "pointer" }}
            onClick={onClickTitle}
          >
            {title}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
          direction="column"
          justifyContent="center"
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
}
