import {
  PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM,
  PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE,
  PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM,
  PROVIDER_DIRECTORY_BASE_URL,
} from "utils/config";

export const PROVIDER_LINK_IDENTIFIER = {
  system: PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_SYSTEM,
  type: {
    coding: [
      {
        code: PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_CODE,
        display: "PatientHolistics Providers Directory",
        system: PRACTITIONER_PROVIDER_DIR_LINK_IDENTIFIER_TYPE_SYSTEM,
      },
    ],
    text: "PatientHolistics Providers Directory",
  },
};

export const PROVIDER_LICENSE_IDENTIFIER = {
  type: {
    coding: [
      {
        code: "providers-directory-license",
        display: "Providers Directory License",
        system: PROVIDER_DIRECTORY_BASE_URL,
      },
    ],
    text: "Providers Directory License",
  },
};

export const PROVIDER_CERTIFICATION_IDENTIFIER = {
  type: {
    coding: [
      {
        code: "providers-directory-certification",
        display: "Providers Directory Certification",
        system: PROVIDER_DIRECTORY_BASE_URL,
      },
    ],
    text: "Providers Directory Certification",
  },
};

export const PROVIDER_SPECIALTY_IDENTIFIER = {
  type: {
    coding: [
      {
        code: "providers-directory-specialty",
        display: "Providers Directory Specialty",
        system: PROVIDER_DIRECTORY_BASE_URL,
      },
    ],
    text: "Providers Directory Specialty",
  },
};

export const NPI_PROVIDER_IDENTIFIER_CODE = "NPI";
export const NPI_IDENTIFIER_SYSTEM = "http://hl7.org/fhir/sid/us-npi";

export const SSN_PROVIDER_IDENTIFIER_CODE = "SSN";
export const SSN_IDENTIFIER_SYSTEM = "http://hl7.org/fhir/sid/us-ssn";

export const MEDICARE_PROVIDER_IDENTIFIER_CODE = "MEDICARE";
export const MEDICARE_IDENTIFIER_SYSTEM = "http://hl7.org/fhir/sid/us-medicare";

export const IDENTIFIER_CODE_SYSTEM_MAPPING: {
  code: string;
  system: string;
}[] = [
  {
    code: NPI_PROVIDER_IDENTIFIER_CODE,
    system: NPI_IDENTIFIER_SYSTEM,
  },
  {
    code: SSN_PROVIDER_IDENTIFIER_CODE,
    system: SSN_IDENTIFIER_SYSTEM,
  },
  {
    code: MEDICARE_PROVIDER_IDENTIFIER_CODE,
    system: MEDICARE_IDENTIFIER_SYSTEM,
  },
];

export const verificationOptions = ["verified", "non-verified"];

export const validTelecomSystems = [
  "phone",
  "fax",
  "email",
  "pager",
  "url",
  "sms",
  "other",
];

export const pcpAssignmentIncidentScopeCodes = {
  PCP_MULTI_MATCH: "nat.task.response.pcp_multi_match",
  HAVE_NO_PCP: "nat.task.response.have_no_pcp",
  MULTIPLE_PCP: "nat.task.response.multiple_pcp",
};

export const genders = ["male", "female", "other"];

export const existingPracttitionerVerificationErrorMessage =
  "There was an error while trying to verify existing Practitioners";

export const linkingPractitionerErrorMessage =
  "There was an error while trying to link Practitioner. Please try again";

export const linkingPractitionerSuccessMessage =
  "Practitioner Succesfully linked";

export const unlinkingPractitionerErrorMessage =
  "There was an error while trying to unlink Practitioner. Please try again";

export const unlinkingPractitionerSuccessMessage =
  "Practitioner Succesfully Unlinked";

export const LOOKUP_ERROR_MESSAGE_CODES = {
  INVALID_VALUE_SET: "INVALID_VALUE_SET",
};
