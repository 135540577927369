import { useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Card,
  Container,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import React, { useContext } from "react";
import ComxInteractivePortal from "./ComxInteractivePortal";
import { ComxInteractiveScreenContext } from "../../contexts/comxInteractiveScreenContext";

const ComxFrameStyle = styled("iframe")(({ theme }) => ({
  height: "700px",
  width: "500px",
  border: "none",
}));

const RootStyle = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  zIndex: "9999 !important",
}));

const MinimizedStyle = styled("div")(({ theme }) => ({
  height: "30px",
  width: "500px",
  backgroundColor: theme.palette.grey[300],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 520,
  margin: "auto",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    zIndex: 11,
    textAlign: "left",
    position: "absolute",
  },
}));

type Props = {};

const ComxInteractiveScreen = (props: Props) => {
  const { isOpen, setIsOpen } = useContext(ComxInteractiveScreenContext);

  const handleToggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const url = `${process.env.REACT_APP_COMX_INTERACTIVE_FRONT_END_URL}/dashboard/chat`;
  const comxEnabled =
    (process.env?.REACT_APP_ENABLE_COMX_INTERACTIVE &&
      process.env?.REACT_APP_ENABLE_COMX_INTERACTIVE === "true") ||
    false;

  console.log("comx url: " + url);
  console.log("comxEnabled", comxEnabled);

  let render = null;
  if (comxEnabled) {
    render = (
      <ComxInteractivePortal wrapperId="comx-interactive-portal-wrapper">
        <RootStyle>
          <Paper elevation={3}>
            <MinimizedStyle onClick={handleToggleOpen}>
              <Typography
                variant="subtitle1"
                paragraph
                sx={{ textAlign: "center", marginBottom: 0 }}
              >
                ComX
              </Typography>
            </MinimizedStyle>

            <ComxFrameStyle
              hidden={!isOpen}
              src={url}
              id="comx-interactive-iframe"
              // allow="microphone https://comx-interactive.com"
              allow="microphone"
            ></ComxFrameStyle>
          </Paper>
        </RootStyle>
      </ComxInteractivePortal>
    );
  }
  return render;
};

export default ComxInteractiveScreen;
