const getBaseUrlNative = (
  eUrl: string | undefined,
  ext: string | undefined
) => {
  try {
    if (!eUrl) throw new Error("Error .");
    return ext ? eUrl + ext : eUrl;
  } catch (err) {
    console.log(err);
  }
};

export const urlBackNestApp: string =
  (getBaseUrlNative(process.env.REACT_APP_URL_BACK, "") as string) || "";

export const doFormAssignUrl: string =
  process.env.REACT_APP_DOFORM_ASSIGN_ENDPOINT || "";
export const doFormRegistrationUrl: string =
  process.env.REACT_APP_DOFORMREGISTRATIONSERVICE_ENDPOINT || "";
export const doFormLanguageUrl: string =
  process.env.REACT_APP_DOFORM_LANGUAGE_ENDPOINT || "";