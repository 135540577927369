import { FhirDomainResourceMod } from "./fhirDomainResource";
import {
  FhirPagination,
  indexedInt,
  IndexedServiceRequestWithTasksNPatientNOrg
} from "./fhirQuery";
import { FhirOrganizationMod } from "./organization";
import { FhirPatientMod } from "./patient";
import { FhirPractitionerMod } from './practitioner';
import { FhirServiceRequestMod } from "./serviceRequest";
import { FhirRelatedPersonMod } from "./relatedPerson";
import { FhirTaskMod } from "./task";

export function indexQueryServiceRequestWithTasksNPatientNOrg(
  bundle: any
): IndexedServiceRequestWithTasksNPatientNOrg {
  const indexedSR: indexedInt<FhirServiceRequestMod.ServiceRequest> = {};
  const indexedTA: indexedInt<FhirTaskMod.Task> = {};
  const indexedPA: indexedInt<FhirPatientMod.Patient> = {};
  const indexedOR: indexedInt<FhirOrganizationMod.Organization> = {};

  bundle?.entry?.forEach((item: any) => {
    if (
      item.resource.resourceType === "ServiceRequest" &&
      item.search.mode === "match"
    ) {
      const serviceRequest = item.resource as FhirServiceRequestMod.ServiceRequest;
      indexedSR[`${serviceRequest.id}`] = serviceRequest;
    } else if (
      item.resource.resourceType === "Task" &&
      item.search.mode === "include"
    ) {
      const task = item.resource as FhirTaskMod.Task;
      indexedTA[`${task.id}`] = task;
    } else if (
      item.resource.resourceType === "Patient" &&
      item.search.mode === "include"
    ) {
      const patient = item.resource as FhirPatientMod.Patient;
      indexedPA[`${patient.id}`] = patient;
    } else if (
      item.resource.resourceType === "Organization" &&
      item.search.mode === "include"
    ) {
      const organization = item.resource as FhirOrganizationMod.Organization;
      indexedOR[`${organization.id}`] = organization;
    }
  });

  const res: IndexedServiceRequestWithTasksNPatientNOrg = {
    serviceRequests: indexedSR,
    tasks: indexedTA,
    patients: indexedPA,
    organizations: indexedOR,
  };
  return res;
}

export function indexQueryTask(
  bundle: any
): IndexedServiceRequestWithTasksNPatientNOrg {
  const indexedTA: indexedInt<FhirTaskMod.Task> = {};
  const indexedPA: any = {};
  const indexedRP: any = {};
  const indexedSR: any = {};
  const indexedPD: any = {};

  bundle?.entry?.forEach((item: any) => {
    if (
      item.resource.resourceType === "Task" &&
      item.search.mode === "match"
    ) {
      const task = item.resource as FhirTaskMod.Task;
      indexedTA[`${task.id}`] = task;
    } else if (
      item.resource.resourceType === "Patient" &&
      item.search.mode === "include"
    ) {
      const patient = item.resource as FhirPatientMod.Patient;
      indexedPA[`${patient.id}`] = patient;
    } else if (
      item.resource.resourceType === "Practitioner" &&
      item.search.mode === "include"
    ) {
      const practitioner = item.resource as FhirPractitionerMod.Practitioner;
      indexedPA[`${practitioner.id}`] = practitioner;
    } else if (
      item.resource.resourceType === "RelatedPerson" &&
      item.search.mode === "include"
    ) {
      const relatedPerson = item.resource as FhirRelatedPersonMod.RelatedPerson;
      indexedRP[`${relatedPerson.id}`] = relatedPerson;
    } else if (
      item.resource.resourceType === "ServiceRequest" &&
      item.search.mode === "include"
    ) {
      const serviceRequest = item.resource as FhirServiceRequestMod.ServiceRequest;
      indexedSR[`${serviceRequest.id}`] = serviceRequest;
    } else if (
      item.resource.resourceType === "PlanDefinition" &&
      item.search.mode === "match"
    ) {
      const planDefinition = item.resource;
      indexedPD[`${planDefinition.id}`] = planDefinition;
    }
  });

  const res: any = {
    tasks: indexedTA,
    patients: indexedPA,
    relatedPersons: indexedRP,
    serviceRequests: indexedSR,
    planDefinitions: indexedPD,
  };

  return res;
}

export function indexResource<T extends FhirDomainResourceMod.DomainResource>(
  bundle: any,
  matchResourceType: string
): indexedInt<T> {
  const indexed: indexedInt<T> = {};

  bundle?.entry?.forEach((item: any) => {
    if (
      item.resource.resourceType === matchResourceType &&
      item.search.mode === "match"
    ) {
      const casted = item.resource as T;
      indexed[`${casted.id}`] = casted;
    }
  });

  return indexed;
}

export function getlistResource<T extends FhirDomainResourceMod.DomainResource>(
  bundle: any,
  matchResourceType: string
): T[] {
  const indexedOR: indexedInt<FhirOrganizationMod.Organization> = {};
  const resources: T[] = [];

  bundle?.entry?.forEach((item: any) => {
    if (
      item.resource.resourceType === matchResourceType &&
      item.search.mode === "match"
    ) {
      const casted = item.resource as T;
      resources.push(casted);
    } else if (
      item.resource.resourceType === "Organization" &&
      item.search.mode === "include"
    ) {
      const organization = item.resource as FhirOrganizationMod.Organization;
      indexedOR[`${organization.id}`] = organization;
    }
  });

  // Todo: Refactor this to fix the types
  const res: any = {
    patients: resources,
    organizations: indexedOR,
  };

  return res;
}

export function getPaginatedResource<T>(
  bundle: any,
  resourcesFromBundle: T | T[]
): FhirPagination<T> {
  const fhirPage: FhirPagination<T> = {
    resources: resourcesFromBundle,
    nextPageToken: undefined,
    firstPageToken: undefined,
    totalMatched: undefined,
    selfPageToken: undefined,
  };

  bundle?.link?.forEach((item: any) => {
    if (item.relation !== undefined && item.url !== undefined) {
      const itemUrlQuery = new URLSearchParams(item.url);
      const pageToken = itemUrlQuery.get("_page_token");
      const encodedPageToken = pageToken === null ? undefined : encodeURIComponent(pageToken);
      const urlStr: string = item.url;
      const regex1 = /([A-z]+\/{1}[?])/g;
      const splitStr = urlStr.split(regex1);
      const queries = splitStr[2] === undefined ? "" : splitStr[2];

      if (item.relation === "next") {
        if (pageToken !== undefined)
          fhirPage.nextPageToken = encodedPageToken;
      } else if (item.relation === "first") {
        fhirPage.firstPageToken = queries;
      } else if (item.relation === "self") {
        if (encodedPageToken === undefined) {
          fhirPage.selfPageToken = queries;
        } else {
          fhirPage.selfPageToken = encodedPageToken;
        }
      }
    }
  });

  if (bundle.total !== undefined) fhirPage.totalMatched = bundle.total;

  return fhirPage;
}
