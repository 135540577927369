import { useState, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import { InactiveUserModal, ExpireSessionModal } from 'components/authentication/verify-user';
// ----------------------------------------------------------------------
import Modal from '@material-ui/core/Modal';

AuthGuard.propTypes = {
  children: PropTypes.node
};

type AuthGuardProps = {
  component: any;
  roles: Array<string>;
};

export default function AuthGuard({ component: Component, roles,  ...rest }: AuthGuardProps) {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if(roles && !roles.some(r => user!.keycloakRole.includes(r))){
    return (
      <Modal
        disablePortal
        disableEscapeKeyDown
        disableScrollLock
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        container={() => rootRef.current}
      >
        <InactiveUserModal msg={'Your are not user from the Portal, please contact you provider'}/>
      </Modal>
    )
  }

  if(user!.status !== 'Active'){
    return (
      <Modal
        disablePortal
        disableEscapeKeyDown
        disableScrollLock
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        container={() => rootRef.current}
      >
        <InactiveUserModal msg='Your account is inactive or suspended, please contact your provider.'/>
      </Modal>
    )
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <Component {...rest}/>;
}
