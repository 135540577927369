import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check, Edit } from "@material-ui/icons";
import { ErrorMessage, Form, Formik } from "formik";
import { FhirPatientMod } from "models/patient";
import { useEffect, useState } from "react";
import { dateYearFormat, phoneFormat } from "utils/datesFormats";
import { InputMasked } from "utils/inputMasked";
import { formatPatient } from "utils/patientFormat";
import * as Yup from "yup";
import { getBaseUrlNative } from "_helpers";
import agent from "../../api/agent";

interface ConfirmMemberInfoProps {
  patientEngagement: any;
  isOpen: boolean;
  handlerIsOpen: Function;
  checklist: any;
  workflow?: any;
  checklistHandler?: any;
  recordHandler?: any;
}

interface OrgOption {
  uuid: string;
  displayName: string;
}

export default function ConfirmMemberInfo({
  patientEngagement,
  isOpen,
  handlerIsOpen,
  checklist,
  workflow,
  checklistHandler,
  recordHandler,
}: ConfirmMemberInfoProps) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(isOpen);
  const [firstNameEditMode, setFirstNameEditMode] = useState(false);
  const [middleNameEditMode, setMiddleNameEditMode] = useState(false);
  const [secondNameEditMode, setSecondNameEditMode] = useState(false);
  const [dobEditMode, setDobEditMode] = useState(false);
  const [genderEditMode, setGenderEditMode] = useState(false);
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [phoneEditMode, setPhoneEditMode] = useState(false);
  const [languageEditMode, setLanguageEditMode] = useState(false);
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [organizationEditMode, setOrganizationEditMode] = useState(false);
  const [patientInfoLoaded, setPatientInfoLoaded] = useState(false);
  const [languagesList, setLanguagesList] = useState<any>([]);
  const [patient, setPatient] = useState<any>(null);
  const [organizations, setOrganizations] = useState<OrgOption[]>([]);
  const [selectedOrgOption, setSelectedOrgOption] = useState<OrgOption>();
  const [addressTypesCatalog, setAddressTypesCatalog] = useState([]);
  const [genderCatalog, setGenderCatalog] = useState([]);
  const [emailTypeCatalog, setEmailTypeCatalog] = useState([]);
  const [phoneTypesCatalog, setPhoneTypesCatalog] = useState([]);

  const _setOrgOptions = async () => {
    const response = await agent.Healthcare.Organization.list();
    const mapped: OrgOption[] =
      (response instanceof Array &&
        response.map((item) => {
          const newItem: OrgOption = {
            uuid: item.idor,
            displayName: item.name,
          };

          return newItem;
        })) ||
      [];

    setOrganizations(mapped);
  };

  const getPatient = async () => {
    setPatientInfoLoaded(false);
    const [, id] = patientEngagement
      ? patientEngagement.fhir_uri.split("/")
      : [null, null];
    const fetchurl: string =
      (getBaseUrlNative(
        process.env.REACT_APP_ORGANIZATIONS_ENDPOINT,
        `/healthcare/patient/${id}`
      ) as string) || "";
    fetch(`${fetchurl}`)
      .then((data) => data.json())
      .then((data) => {
        setPatient(formatPatient(data));
      })
      .finally(() => {
        setPatientInfoLoaded(true);
      });
  };

  const getLanguages = async () => {
    const response: any = await agent.FhirValueSet.details("simple-language");
    setLanguagesList(response?.compose?.include?.[0]?.concept);
  };

  const getAddressTypes = async () => {
    const response = await agent.Catalog.getByCode("ADDRESS_TYPES");
    setAddressTypesCatalog(response);
  };

  const getGenderCatalog = async () => {
    const response = await agent.Catalog.getByCode("GENDERS");
    setGenderCatalog(response);
  };

  const getEmailTypes = async () => {
    const response = await agent.Catalog.getByCode("EMAIL_TYPES");
    setEmailTypeCatalog(response);
  };

  const getPhoneTypes = async () => {
    const response = await agent.Catalog.getByCode("PHONE_TYPES");
    setPhoneTypesCatalog(response);
  };

  useEffect(() => {
    getLanguages();
    getAddressTypes();
    getGenderCatalog();
    getEmailTypes();
    getPhoneTypes();
  }, []);

  useEffect(() => {
    if (isOpen) {
      getPatient();
      _setOrgOptions();
    }
    if (!isOpen) {
      setFirstNameEditMode(false);
      setMiddleNameEditMode(false);
      setSecondNameEditMode(false);
      setDobEditMode(false);
      setGenderEditMode(false);
      setEmailEditMode(false);
      setPhoneEditMode(false);
      setLanguageEditMode(false);
      setAddressEditMode(false);
      setOrganizationEditMode(false);
    }
    setConfirmModalOpen(isOpen);
  }, [isOpen]);

  const generalInfoSchema = Yup.object().shape({
    given: Yup.string().required("First Name is required"),
    middle_name: Yup.string(),
    family: Yup.string().required("Last Name is required"),
    birthdate: Yup.date().required("Date of birth is required"),
    gender: Yup.string().required("Gender is required"),
    emailType: Yup.string().required("Email Type is required"),
    emailAddress: Yup.string()
      .email("Email must be a valid email address")
      .required("Email Address is required"),
    phoneType: Yup.string().required("Phone Type is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    address_line: Yup.string().required("Street is required"),
    address_line_2: Yup.string(),
    address_city: Yup.string().required("City is required"),
    address_state: Yup.string().required("State is required"),
    address_postal: Yup.number().required("Zip Code is required"),
  });

  const initialValues = {
    given: patient?.name?.given,
    middle_name: patient?.name?.middle_name && patient?.name?.middle_name,
    family: patient?.name?.family,
    birthdate: patient?.birthDate,
    gender: patient?.gender,
    emailType: patient?.email[0]?.emailType,
    emailAddress: patient?.email[0]?.email,
    phoneType: patient?.telecom_value[0]?.phoneType,
    phoneNumber: patient?.telecom_value[0]?.phone,
    language: patient?.languages[0]?.language,
    address_use: patient?.address?.use,
    address_line: patient?.address?.line,
    address_line_2: patient?.address?.line_2,
    address_city: patient?.address?.city,
    address_state: patient?.address?.state,
    address_postal: patient?.address?.postalCode,
    organization: patient?.managing_organization,
    mrn: patient?.mrn
  };

  const handleSubmit = async (values: any) => {
    const patientData = {
      ...values,
      active: true,
      id: patientEngagement.fhir_uri.split("/")[1],
      patientEngagementId: patientEngagement.id,
      language: [
        {
          language: values.language,
          isDefault: true,
        },
      ],
      email: [
        {
          email: values.emailAddress,
          emailType: values.emailType,
          isDefault: true,
        },
      ],
      telecom_value: [
        {
          phone: values.phoneNumber,
          phoneType: values.phoneType,
          isDefault: true,
        },
      ],
    };
    const managingOrganization:
      | false
      | FhirPatientMod.Patient = selectedOrgOption !== undefined &&
      selectedOrgOption !== null && {
        id: patientEngagement.fhir_uri.split("/")[1],
        active: true,
        managingOrganization: {
          display: selectedOrgOption.displayName,
          reference: `Organization/${selectedOrgOption.uuid}`,
        },
        resourceType: "Patient",
      };
    const data = {
      ...values,
      checklistItemId: checklist.id,
      workflowStageChecklistRef: null,
      patientEngagementRef: patientEngagement.id,
      code: "CONFIRM_MEMBER_INFO_ITEM",
      message: "Member info confirmed",
      patientData: patientData,
    };

    try {
      const response = await agent.PatientEngagement.checkitem(data);
      handlerIsOpen(false);
      setConfirmModalOpen(false);
      if (checklistHandler) checklistHandler();
      if (recordHandler) recordHandler();
      if (managingOrganization)
        await agent.Healthcare.Patient.fhirPatch(managingOrganization);
    } catch (e) {
      console.log(e);
    }
  };

  const [displayInfo, setDisplayInfo] = useState(initialValues);

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      {patientInfoLoaded && patient !== null ? (
        <Formik
          initialValues={initialValues}
          validationSchema={generalInfoSchema}
          onSubmit={handleSubmit}
        >
          {(props: any) => {
            setDisplayInfo(props.values);
            return (
              <Form id="confirmMemberInfoForm" autoComplete="off">
                <List component="nav">
                  <ListItem>
                    <Grid container>
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={props.isSubmitting}
                        >
                          Confirm Information
                        </Button>
                        <Button
                          onClick={() => {
                            setConfirmModalOpen(false);
                            handlerIsOpen(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid
                      container
                      direction="column"
                      marginTop={2}
                      width={800}
                    >
                      {/* <Grid
                            container
                            direction="row"
                            alignItems="center">
                            <Grid
                              container
                              item
                              xs={3}>
                              <Typography>
                                Avatar
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={8}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar src="/static/mock-images/avatars/avatar_default.jpg" />
                                <IconButton>
                                  <AddCircleOutlineOutlined />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </Grid> */}

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>First Name</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {firstNameEditMode ? (
                            <TextField
                              id="given"
                              placeholder="First Name"
                              fullWidth
                              {...props.getFieldProps("given")}
                              error={Boolean(
                                props.touched.given && props.errors.given
                              )}
                              helperText={
                                props.touched.given && props.errors.given
                              }
                            />
                          ) : (
                            <Stack>
                              <Typography>
                                <b>{displayInfo.given}</b>
                              </Typography>
                              <ErrorMessage name="given">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() =>
                              setFirstNameEditMode((prev) => !prev)
                            }
                          >
                            {firstNameEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Middle Name</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {middleNameEditMode ? (
                            <TextField
                              id="middle_name"
                              placeholder="Middle Name"
                              fullWidth
                              {...props.getFieldProps("middle_name")}
                              error={Boolean(
                                props.touched.middle_name &&
                                  props.errors.middle_name
                              )}
                              helperText={
                                props.touched.middle_name &&
                                props.errors.middle_name
                              }
                            />
                          ) : (
                            <Typography>
                              <b>{displayInfo.middle_name}</b>
                            </Typography>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() =>
                              setMiddleNameEditMode((prev) => !prev)
                            }
                          >
                            {middleNameEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Last Name</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {secondNameEditMode ? (
                            <TextField
                              id="family"
                              placeholder="Second Name"
                              fullWidth
                              {...props.getFieldProps("family")}
                              error={Boolean(
                                props.touched.family && props.errors.family
                              )}
                              helperText={
                                props.touched.family && props.errors.family
                              }
                            />
                          ) : (
                            <Stack>
                              <Typography>
                                <b>{displayInfo.family}</b>
                              </Typography>
                              <ErrorMessage name="family">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() =>
                              setSecondNameEditMode((prev) => !prev)
                            }
                          >
                            {secondNameEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Date of Birth</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {dobEditMode ? (
                            <TextField
                              id="birthdate"
                              placeholder="Date of Birth"
                              type="date"
                              fullWidth
                              {...props.getFieldProps("birthdate")}
                              error={Boolean(
                                props.touched.birthdate &&
                                  props.errors.birthdate
                              )}
                              helperText={
                                props.touched.birthdate &&
                                props.errors.birthdate
                              }
                            />
                          ) : (
                            <Stack>
                              <Typography>
                                <b>{dateYearFormat(displayInfo.birthdate)}</b>
                              </Typography>
                              <ErrorMessage name="birthdate">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setDobEditMode((prev) => !prev)}
                          >
                            {dobEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Gender</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {genderEditMode ? (
                            <TextField
                              id="gender"
                              placeholder="Gender"
                              fullWidth
                              select
                              SelectProps={{
                                native: true,
                              }}
                              {...props.getFieldProps("gender")}
                              error={Boolean(
                                props.touched.gender && props.errors.gender
                              )}
                              helperText={
                                props.touched.gender && props.errors.gender
                              }
                            >
                              <option value="" disabled></option>
                              {genderCatalog.map((gender: any) => (
                                <option value={gender.valueCode}>
                                  {gender.valueCode}
                                </option>
                              ))}
                            </TextField>
                          ) : (
                            <Stack>
                              <Typography>
                                <b>{displayInfo.gender}</b>
                              </Typography>
                              <ErrorMessage name="gender">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setGenderEditMode((prev) => !prev)}
                          >
                            {genderEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Email</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {emailEditMode ? (
                            <>
                              <Grid xs={3}>
                                <TextField
                                  id="emailType"
                                  placeholder="Email Type"
                                  fullWidth
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  {...props.getFieldProps("emailType")}
                                  error={Boolean(
                                    props.touched.emailType &&
                                      props.errors.emailType
                                  )}
                                  helperText={
                                    props.touched.emailType &&
                                    props.errors.emailType
                                  }
                                >
                                  <option value="" disabled></option>
                                  {emailTypeCatalog.map((type: any) => (
                                    <option value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid xs={9}>
                                <TextField
                                  id="email"
                                  placeholder="Email"
                                  fullWidth
                                  type="email"
                                  {...props.getFieldProps("emailAddress")}
                                  error={Boolean(
                                    props.touched.emailAddress &&
                                      props.errors.emailAddress
                                  )}
                                  helperText={
                                    props.touched.emailAddress &&
                                    props.errors.emailAddress
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={3}>
                                <Typography>
                                  <b>{displayInfo.emailType}</b>
                                </Typography>
                                <ErrorMessage name="emailType">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                              </Grid>

                              <Grid xs={9}>
                                <Typography>
                                  <b>{displayInfo.emailAddress}</b>
                                  <ErrorMessage name="emailAddress">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setEmailEditMode((prev) => !prev)}
                          >
                            {emailEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Phone</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {phoneEditMode ? (
                            <>
                              <Grid xs={3}>
                                <TextField
                                  id="phoneType"
                                  placeholder="Phone Type"
                                  fullWidth
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  {...props.getFieldProps("phoneType")}
                                  error={Boolean(
                                    props.touched.phoneType &&
                                      props.errors.phoneType
                                  )}
                                  helperText={
                                    props.touched.phoneType &&
                                    props.errors.phoneType
                                  }
                                >
                                  <option value="" disabled></option>
                                  {phoneTypesCatalog.map((type: any) => (
                                    <option value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid xs={9}>
                                <InputMasked
                                  id="phoneNumber"
                                  placeholder="Phone Number"
                                  fullWidth
                                  type="phone"
                                  {...props.getFieldProps("phoneNumber")}
                                  error={Boolean(
                                    props.touched.phoneNumber &&
                                      props.errors.phoneNumber
                                  )}
                                  helperText={
                                    props.touched.phoneNumber &&
                                    props.errors.phoneNumber
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={3}>
                                <Typography>
                                  <b>{displayInfo.phoneType}</b>
                                </Typography>
                                <ErrorMessage name="phoneType">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                              </Grid>

                              <Grid xs={9}>
                                <Typography>
                                  <b>{phoneFormat(displayInfo.phoneNumber)}</b>
                                  <ErrorMessage name="phoneNumber">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setPhoneEditMode((prev) => !prev)}
                          >
                            {phoneEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Language</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {languageEditMode ? (
                            <TextField
                              id="language"
                              placeholder="Language"
                              fullWidth
                              select
                              SelectProps={{
                                native: true,
                              }}
                              {...props.getFieldProps("language")}
                              error={Boolean(
                                props.touched.language && props.errors.language
                              )}
                              helperText={
                                props.touched.language && props.errors.language
                              }
                            >
                              <option value="" disabled></option>
                              {languagesList.map((lang: any) => (
                                <option value={lang.display}>
                                  {lang.display}
                                </option>
                              ))}
                            </TextField>
                          ) : (
                            <Stack>
                              <Typography>
                                <b>{displayInfo.language}</b>
                              </Typography>
                              <ErrorMessage name="language">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setLanguageEditMode((prev) => !prev)}
                          >
                            {languageEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Address</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {addressEditMode ? (
                            <>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_use"
                                  placeholder="Use"
                                  fullWidth
                                  label="Use"
                                  select
                                  {...props.getFieldProps("address_use")}
                                  error={Boolean(
                                    props.touched.address_use &&
                                      props.errors.address_use
                                  )}
                                  helperText={
                                    props.touched.address_use &&
                                    props.errors.address_use
                                  }
                                >
                                  {addressTypesCatalog.map((type: any) => (
                                    <MenuItem value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_line"
                                  placeholder="Street"
                                  label="Street"
                                  fullWidth
                                  type="address"
                                  {...props.getFieldProps("address_line")}
                                  error={Boolean(
                                    props.touched.address_line &&
                                      props.errors.address_line
                                  )}
                                  helperText={
                                    props.touched.address_line &&
                                    props.errors.address_line
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_line_2"
                                  placeholder="Street 2"
                                  label="Street 2"
                                  fullWidth
                                  type="address"
                                  {...props.getFieldProps("address_line_2")}
                                  error={Boolean(
                                    props.touched.address_line_2 &&
                                      props.errors.address_line_2
                                  )}
                                  helperText={
                                    props.touched.address_line_2 &&
                                    props.errors.address_line_2
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_city"
                                  placeholder="City"
                                  label="City"
                                  fullWidth
                                  {...props.getFieldProps("address_city")}
                                  error={Boolean(
                                    props.touched.address_city &&
                                      props.errors.address_city
                                  )}
                                  helperText={
                                    props.touched.address_city &&
                                    props.errors.address_city
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_state"
                                  placeholder="address_State"
                                  label="State"
                                  fullWidth
                                  {...props.getFieldProps("address_state")}
                                  error={Boolean(
                                    props.touched.address_state &&
                                      props.errors.address_state
                                  )}
                                  helperText={
                                    props.touched.address_state &&
                                    props.errors.address_state
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_postal"
                                  placeholder="Zip Code"
                                  label="Zip Code"
                                  fullWidth
                                  {...props.getFieldProps("address_postal")}
                                  error={Boolean(
                                    props.touched.address_postal &&
                                      props.errors.address_postal
                                  )}
                                  helperText={
                                    props.touched.address_postal &&
                                    props.errors.address_postal
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={3}>
                                <Typography>
                                  <b>{displayInfo.address_use}</b>
                                </Typography>
                                <ErrorMessage name="address_use">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                              </Grid>

                              <Grid xs={9}>
                                <Typography>
                                  <b>{`${displayInfo.address_line}, ${displayInfo.address_line_2} ${displayInfo.address_city}, ${displayInfo.address_state}, ${displayInfo.address_postal}`}</b>
                                </Typography>
                                <Stack>
                                  <ErrorMessage name="address_line">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                  <ErrorMessage name="address_line_2">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                  <ErrorMessage name="address_city">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                  <ErrorMessage name="address_state">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                  <ErrorMessage name="address_postal">
                                    {(msg) => (
                                      <small className="validationError">
                                        {msg}
                                      </small>
                                    )}
                                  </ErrorMessage>
                                </Stack>
                              </Grid>
                            </>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() => setAddressEditMode((prev) => !prev)}
                          >
                            {addressEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container item xs={3}>
                          <Typography>Organization</Typography>
                        </Grid>

                        <Grid container item xs={8}>
                          {organizationEditMode ? (
                            <Autocomplete<
                              OrgOption,
                              undefined,
                              undefined,
                              undefined
                            >
                              options={organizations}
                              getOptionLabel={(orgOption: OrgOption) =>
                                orgOption.displayName
                              }
                              onChange={(event, newValue: OrgOption | null) => {
                                if (newValue) {
                                  setSelectedOrgOption(newValue);
                                }
                              }}
                              style={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select an organization"
                                  variant="outlined"
                                  error={Boolean(
                                    props.touched.organization &&
                                      props.errors.organization
                                  )}
                                  helperText={
                                    props.touched.organization &&
                                    props.errors.organization
                                  }
                                />
                              )}
                            />
                          ) : (
                            <Typography>
                              <b>{displayInfo.organization}</b>
                            </Typography>
                          )}
                        </Grid>

                        <Grid container item xs={1}>
                          <IconButton
                            onClick={() =>
                              setOrganizationEditMode((prev) => !prev)
                            }
                          >
                            {organizationEditMode ? <Check /> : <Edit />}
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Stack width={500} alignItems="center" marginTop={50}>
          <CircularProgress />
        </Stack>
      )}
    </Drawer>
  );
}
