import { Grid, Typography } from "@material-ui/core";
import agent from "api/agent";
import { useEffect, useState } from "react";

export const OptDisplay = ({patientId} : {patientId : string}) => {
  const [consent, setConsent] = useState("");

  useEffect(() => {
    getPatientConsent();
  }, []);

  const getPatientConsent = async () => {
    const response = await agent.FhirPatientConsent.byPatient(patientId);
    if ((response?.["entry"]?.[0]?.["resource"]?.["policyRule"]?.["coding"]?.[0]?.["code"])) {
      if (response["entry"][0]["resource"]["policyRule"]["coding"][0]["code"] === "OIC" || response["entry"][0]["resource"]["policyRule"]["coding"][0]["code"] === "OIS") {
        setConsent("I want to tell my HealthStory and will share my personal information so I may receive NJ InCK services.");
      }
      if (response["entry"][0]["resource"]["policyRule"]["coding"][0]["code"] === "OOC" || response["entry"][0]["resource"]["policyRule"]["coding"][0]["code"] === "OOS") {
        setConsent("I don’t want to share my HealthStory personal information. By not sharing, I will not be eligible for NJ InCK services.");
      }
    }
    else {
      setConsent("Not available")
    }
  };


  return (
    <Grid container item md={12} alignItems="baseline" style={{ padding: 16 }}>
      <Typography variant="h4">Consent:</Typography>
      <Typography>
        &nbsp;
      </Typography>
      <Typography variant="p">
        {consent}
      </Typography>
    </Grid>
  );
};