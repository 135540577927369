import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@material-ui/core";
import { ITaskRowInfo } from "../../@types/patientEngagement";
import KeyValueGrid from "components/KeyValueGrid";
import KeyValuePaper from "components/KeyValuePaper";
import useAuth from "hooks/useAuth";
import { IndexedTasksWithServiceRequestsAndOwner } from "models/fhirQuery";
import { FhirPatientMod } from "models/patient";
import { FhirRelatedPersonMod } from "models/relatedPerson";
import { FhirTaskMod } from "models/task";
import { useEffect, useState } from "react";
import { urlBackNestApp } from "routes/urls";
import {
  getSplitReference,
  getTaskNumberFromIdentifiers,
} from "utils/fhirUtils";
import agent from "../../api/agent";
import TaskRow from "./TaskRow";

interface ITasksList {
  patientId: string;
  isOpen: boolean;
  handlerIsOpen: (isOpen: boolean) => void;
  addPatientEngagementChecklistItem: (data?: any) => void;
}

export default function TasksList({
  patientId,
  isOpen,
  handlerIsOpen,
  addPatientEngagementChecklistItem,
}: ITasksList) {
  const { user } = useAuth();
  const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState<boolean>(
    false
  );
  const [taskRowInfoList, setTaskRowInfoList] = useState<ITaskRowInfo[]>();
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  useEffect(() => {
    if (patientId && isOpen) {
      setOpenLoadingBackdrop(true);
      getTasksByPatientUuuid(patientId);
    }
  }, [patientId, isOpen]);

  useEffect(() => {
    let taskTimer: any = null;
    if (taskRowInfoList?.length === 0 && alreadyLoaded && patientId) {
      console.log("starting timer for next task request");
      taskTimer = setTimeout(() => getTasksByPatientUuuid(patientId), 2000);
    }

    return () => {
      if (taskTimer) {
        clearTimeout(taskTimer);
      }
    };
  }, [taskRowInfoList, alreadyLoaded]);

  const checkAgain = () => setTimeout(() => getTasksByPatientUuuid(patientId), 2000);

  const getTasksByPatientUuuid = async (patientUuid: string) => {
    const indexedTasksWithServiceRequests: IndexedTasksWithServiceRequestsAndOwner = await agent.FhirTask.byPatientWithServiceRequestAndOwner(
      patientUuid
    );

    const taskRowInfo: ITaskRowInfo[] = Object.keys(
      indexedTasksWithServiceRequests.tasks
    ).map((taskId: string) => {
      let taskRowInfo: ITaskRowInfo = {
        id: taskId,
        name: "-",
        taskNumber: "-",
        type: "-",
        userName: user?.userName,
      };

      const fhirTask: FhirTaskMod.Task =
        indexedTasksWithServiceRequests.tasks[taskId];

      if (fhirTask.identifier !== undefined) {
        const taskNumber = getTaskNumberFromIdentifiers(fhirTask.identifier);

        if (taskNumber !== undefined) taskRowInfo.taskNumber = taskNumber;
      }

      const ownerSplitRef =
        fhirTask.owner !== undefined && fhirTask.owner.reference !== undefined
          ? getSplitReference(fhirTask.owner.reference)
          : undefined;

      if (
        ownerSplitRef !== undefined &&
        ownerSplitRef.resourceType === "Patient"
      ) {
        const patientOwner: FhirPatientMod.Patient =
          indexedTasksWithServiceRequests.patientOwner[ownerSplitRef.uuid];
        taskRowInfo.name = ` ${patientOwner?.name?.[0]?.given?.[0]} ${patientOwner?.name?.[0]?.given?.[0]} `;
        taskRowInfo.type = "Patient";
      }

      if (
        ownerSplitRef !== undefined &&
        ownerSplitRef.resourceType === "RelatedPerson"
      ) {
        const relatedPersonOwner: FhirRelatedPersonMod.RelatedPerson =
          indexedTasksWithServiceRequests.relatedPersonOwner[
            ownerSplitRef.uuid
          ];
        taskRowInfo.name = ` ${relatedPersonOwner?.name?.[0]?.given?.[0]} ${relatedPersonOwner?.name?.[0]?.given?.[0]} `;
        taskRowInfo.type = "Guarantor";
      }

      return taskRowInfo;
    });

    setTaskRowInfoList(taskRowInfo);
    setAlreadyLoaded(true);
    setOpenLoadingBackdrop(false);
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { width: "60%" },
        }}
        anchor="left"
        open={isOpen}
        onClose={() => {
          handlerIsOpen(false);
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={openLoadingBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <List component="nav">
          <ListItem>
            <Grid container>
              <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: '100%' }}>
                <Button
                  onClick={() => {
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={checkAgain}
                  variant="contained"
                >
                  Check again
                </Button>
              </Stack>
            </Grid>
          </ListItem>
        </List>

        <Divider sx={{ marginBottom: 2 }} />

        <Grid container spacing={3} direction="row">
          <Grid
            item
            md={12}
            width="100%"
            container
            flexDirection="row"
            spacing={2}
            className="request-details-container"
          >
            {taskRowInfoList &&
              taskRowInfoList.map((item: ITaskRowInfo) => (
                <TaskRow taskRowInfo={item} />
              ))}
            {alreadyLoaded && taskRowInfoList?.length === 0 && (
              <Typography>Task is being created...</Typography>
            )}
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
