// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
    patients: path(ROOTS_DASHBOARD, '/app/patients'),
    request: path(ROOTS_DASHBOARD, '/app/request'),
    tasks: path(ROOTS_DASHBOARD, '/app/tasks'),
    users: path(ROOTS_DASHBOARD, '/app/users'),
    organizations: path(ROOTS_DASHBOARD, '/app/organizations'),
    falloutscreen: path(ROOTS_DASHBOARD, '/app/incident'),
    provider: path(ROOTS_DASHBOARD, '/app/provider'),
    myprofile: path(ROOTS_DASHBOARD, '/app/myprofile'),
    bulkupload: path(ROOTS_DASHBOARD, '/app/patients/bulkupload'),
    patientEngagements: path(ROOTS_DASHBOARD, '/app/patient-engagements')
  }
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};
