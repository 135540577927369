import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
//
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import UserNotifications from './UserNotifications';
import UserSupport from './UserSupport';
import { urlBackNestApp } from "routes/urls";
import useAuth from 'hooks/useAuth';
import { useEffect, useState, useCallback } from 'react';
import { getBaseUrlNative } from "../../_helpers";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const notificationsMock = [
  {
    id: 1,
    title: 'Martin Williams',
    description: 'has been updated a few minutes ago',
    path: '/dashboard/app/patients',
    isRead: false,
  },
  {
    id: 2,
    title: 'Carlos Hernand',
    description: 'has been updated a few minutes ago',
    isRead: false,
  },
  {
    id: 3,
    title: 'Jaime Rodriguez',
    description: 'has been updated a few minutes ago',
    isRead: false,
  }
];

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { user } = useAuth();
  const { acl = [] }: any = user;
  const SUPPORT_ACCESS = 'Support.Access';
  const getUserSupportACL = acl.filter((i: any) => i.name === SUPPORT_ACCESS);
  
  const [userNotificationsList, setUserNotificationsList] = useState([]);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    // fetchNotifications();
    fetchUser();
  }, []);

  // const fetchNotifications = () => {
  //   fetch(`${urlBackNestApp}/notifications/user/${user?.userName}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
  //     },
  //   })
  //     .then((notifications) => notifications.json())
  //     .then((notifications) => setUserNotificationsList(notifications));
  // };

  const fetchUser = useCallback(() => {
    fetch(`${urlBackNestApp}/users/get-user-organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
      },
      body: JSON.stringify({ userName: user?.userName }),
    })
      .then((userInfo) => userInfo.json())
      .then((userInfo) => setUserInfo(userInfo || {}))
      .catch((error)=>{
        console.log("Error while trying to fetch User", error);
      })
  }, [userInfo]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          <UserNotifications
            notifications={userNotificationsList}
            // fetchNotifications={fetchNotifications}
            sx={{ marginRight: "-15px" }}
          />
          {getUserSupportACL && !!Object.keys(getUserSupportACL).length && (
            <UserSupport />
          )}
          <AccountPopover userInfo={userInfo} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
