import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import { Check, Edit } from "@material-ui/icons";
import axios from "axios";
import { ErrorMessage, Form, Formik } from "formik";
import { FhirDataTypesMod } from "models/fhirDataTypes";
import { FhirPatientMod } from "models/patient";
import { useEffect, useState } from "react";
import { urlBackNestApp } from "routes/urls";
import { phoneFormat } from "utils/datesFormats";
import { InputMasked } from "utils/inputMasked";
import { removeDash } from "utils/phone_format";
import * as Yup from "yup";
import { getBaseUrlNative } from "_helpers";
import agent from "../../api/agent";

interface ConfirmGuarantorInfoProps {
  patientEngagement: any;
  isOpen: boolean;
  handlerIsOpen: Function;
  checklist: any;
  workflow?: any;
  checklistHandler?: any;
}

export default function ConfirmGuarantorInfo({
  patientEngagement,
  isOpen,
  handlerIsOpen,
  checklist,
  checklistHandler,
}: ConfirmGuarantorInfoProps) {
  const [
    confirmGuarantorInfoModalOpen,
    setConfirmGuarantorModalOpen,
  ] = useState<boolean>(isOpen);
  const [guarantor, setGuarantor] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [languagesList, setLanguagesList] = useState<any>([]);
  const [guarantorLoaded, setGuarantorLoaded] = useState<boolean>(false);
  const [addressTypesCatalog, setAddressTypesCatalog] = useState([]);
  const [genderCatalog, setGenderCatalog] = useState([]);
  const [emailTypeCatalog, setEmailTypeCatalog] = useState([]);
  const [phoneTypesCatalog, setPhoneTypesCatalog] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const getLanguages = async () => {
    const response: any = await agent.FhirValueSet.details("simple-language");
    setLanguagesList(response?.compose?.include?.[0]?.concept);
  };

  const getAddressTypes = async () => {
    const response = await agent.Catalog.getByCode("ADDRESS_TYPES");
    setAddressTypesCatalog(response);
  };

  const getGenderCatalog = async () => {
    const response = await agent.Catalog.getByCode("GENDERS");
    setGenderCatalog(response);
  };

  const getEmailTypes = async () => {
    const response = await agent.Catalog.getByCode("EMAIL_TYPES");
    setEmailTypeCatalog(response);
  };

  const getPhoneTypes = async () => {
    const response = await agent.Catalog.getByCode("PHONE_TYPES");
    setPhoneTypesCatalog(response);
  };

  useEffect(() => {
    getLanguages();
    getAddressTypes();
    getGenderCatalog();
    getEmailTypes();
    getPhoneTypes();
  }, []);

  const getGuarantor = async () => {
    setGuarantorLoaded(false);
    const [, id] = patientEngagement
      ? patientEngagement.fhir_uri.split("/")
      : [null, null];
    const fetchurl: string =
      (getBaseUrlNative(
        process.env.REACT_APP_ORGANIZATIONS_ENDPOINT,
        `/healthcare/patient/patient-contacts/${id}`
      ) as string) || "";
    fetch(`${fetchurl}`)
      .then((data) => data.json())
      .then((data) => {
        if (data[0] && data[0] !== "No contacts") setGuarantor(data);
      })
      .finally(() => {
        setGuarantorLoaded(true);
      });
  };

  useEffect(() => {
    if (isOpen) getGuarantor();
    if (!isOpen) setEditMode(false);
  }, [isOpen]);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const guarantorInfoSchema = Yup.object().shape({
    given: Yup.string().required("First Name is required"),
    middle_name: Yup.string(),
    family: Yup.string().required("Last Name is required"),
    /* birthDate: Yup.date().required("Date of birth is required"), */
    gender: Yup.string().required("Gender is required"),
    emailType: Yup.string().required("Email Type is required"),
    emailAddress: Yup.string()
      .email("Email must be a valid email address")
      .required("Email Address is required"),
    phoneType: Yup.string().required("Phone Type is required"),
    phoneNumber: Yup.string().matches(phoneRegExp, "Phone Number is required"),
    address_use: Yup.string().required("Address use is required"),
    address_line: Yup.string().required("Street is required"),
    address_line_2: Yup.string(),
    address_city: Yup.string().required("City is required"),
    address_state: Yup.string().required("State is required"),
    address_postal: Yup.number().required("Zip Code is required"),
  });

  const initialValues = {
    given: (guarantor && guarantor?.[0]["name"]?.["given"][0]) || "",
    middle_name: (guarantor && guarantor?.[0]?.["name"]?.["given"]?.[1]) || "",
    family: (guarantor && guarantor?.[0]?.["name"]?.["family"]) || "",
    /* birthDate: (guarantor && guarantor?.[0]?.["birthDate"]) || "", */
    gender: (guarantor && guarantor?.[0]?.["gender"]) || "",
    emailType: (guarantor && guarantor?.[0]?.["telecom"]?.[0]?.["use"]) || "",
    emailAddress:
      (guarantor && guarantor?.[0]?.["telecom"]?.[0]?.["value"]) || "",
    phoneType: (guarantor && guarantor?.[0]?.["telecom"]?.[1]?.["use"]) || "",
    phoneNumber:
      (guarantor && guarantor?.[0]?.["telecom"]?.[1]?.["value"]) || "",
    language: (() => {
      const defaultRes = [
        {
          language: "",
          isDefault: true,
        },
      ];

      if (guarantor?.[0].communication === undefined) {
        return defaultRes;
      }

      const res = guarantor?.[0].communication.map((val: any) => {
        return {
          language:
            val["language"]["text"] === undefined
              ? ""
              : val["language"]["text"],
          isDefault: val["preferred"],
        };
      });

      if (Array.isArray(res) && res.length > 0) return res;

      return defaultRes;
    })()["language"],
    address_use: (guarantor && guarantor?.[0]?.["address"]?.["use"]) || "",
    address_line:
      (guarantor && guarantor?.[0]?.["address"]?.["line"]?.[0]) || "",
    address_line_2:
      (guarantor && guarantor?.[0]?.["address"]?.["line"]?.[1]) || "",
    address_city: (guarantor && guarantor?.[0]?.["address"]?.["city"]) || "",
    address_state: (guarantor && guarantor?.[0]?.["address"]?.["state"]) || "",
    address_postal:
      (guarantor && guarantor?.[0]?.["address"]?.["postalCode"]) || "",
  };

  useEffect(() => {
    setConfirmGuarantorModalOpen(isOpen);
  }, [isOpen]);

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      checklistItemId: checklist.id,
      workflowStageChecklistRef: null,
      patientEngagementRef: patientEngagement.id,
      code: "CONFIRM_GUARANTOR_INFO_ITEM",
      message: "Guarantor info confirmed",
    };

    agent.PatientEngagement.checkitem(data)
      .then((response) => {
        const [, id] = patientEngagement
          ? patientEngagement.fhir_uri.split("/")
          : [null, null];
        const patientData: FhirPatientMod.Patient = {
          contact: guarantor ?? {},
          resourceType: "Patient",
        }; //const posturl: string = `http://localhost:4000/api/healthcare/patient/${data.id}`;

        let givenArray: string[] = [];

        if (values.given !== undefined) {
          givenArray.push(values.given);
        }

        if (values.middle_name !== undefined) {
          givenArray.push(values.middle_name);
        }

        let lineArray: string[] = [];

        if (values.address_line !== undefined) {
          lineArray.push(values.address_line);
        }

        let telecomArray: FhirDataTypesMod.ContactPoint[] = [];

        if (values.emailAddress) {
          telecomArray.push({
            system: "email",
            value: values.emailAddress,
            use: values.emailType,
          });
        }

        if (values.phoneNumber) {
          telecomArray.push({
            system: "phone",
            value: removeDash(values.phoneNumber),
            use: values.phoneType,
          });
        }

        const patientRelationship: FhirPatientMod.Contact = {
          name: {
            family: values.family,
            given: givenArray,
          },
          address: {
            line: lineArray,
            city: values.address_city,
            state: values.address_state,
            postalCode: values.address_postal,
            use: values.address_use,
          },
          telecom: telecomArray,
          id: guarantor?.[0]?.id,
          ...(guarantor !== null && { editRecord: 0 }),
        };

        if (values.gender) patientRelationship["gender"] = values.gender;

        if (editMode && patientData.contact && guarantor !== null) {
          patientData.contact[0] = patientRelationship;
        } else {
          patientData["contact"] = [];
          patientData.contact?.push(patientRelationship);
        }

        axios
          .put(
            `${urlBackNestApp}/healthcare/patient/fhir/${id}`,
            patientData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
              },
            }
          )
          .then((response) => {
            if (checklistHandler) checklistHandler();
          })
          .catch((reason) => {});

        handlerIsOpen(false);
        setConfirmGuarantorModalOpen(false);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const handleCloseAlert = () => setOpenAlert(false);

  const handleAgreeAlert = () => {
    setOpenAlert(false);
    setConfirmGuarantorModalOpen(false);
    handlerIsOpen(false);
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      {guarantorLoaded ? (
        <Formik
          initialValues={initialValues}
          validationSchema={guarantorInfoSchema}
          onSubmit={handleSubmit}
        >
          {(props: any) => {
            return (
              <Form id="confirmGuarantorInfoForm" autoComplete="off">
                <List component="nav">
                  <ListItem>
                    <Grid container justifyContent="space-between">
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={props.isSubmitting}
                        >
                          Confirm Guarantor Info
                        </Button>
                        <Button
                          onClick={() => setOpenAlert(true)}
                        >
                          Cancel
                        </Button>
                      </Stack>
                      <IconButton onClick={() => setEditMode((prev) => !prev)}>
                        {editMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid
                      container
                      direction="column"
                      marginTop={2}
                      width={800}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        height={editMode ? 180 : 60}
                      >
                        <Grid container item xs={5}>
                          <Typography>Name</Typography>
                        </Grid>

                        <Grid container item xs={7}>
                          {editMode ? (
                            <Stack
                              direction="column"
                              spacing={1}
                              sx={{ width: "100%" }}
                            >
                              <Grid xs={12}>
                                <TextField
                                  id="given"
                                  placeholder="First Name"
                                  label="First Name"
                                  fullWidth
                                  {...props.getFieldProps("given")}
                                  error={Boolean(
                                    props.touched.given && props.errors.given
                                  )}
                                  helperText={
                                    props.touched.given && props.errors.given
                                  }
                                />
                              </Grid>
                              <Grid xs={12}>
                                <TextField
                                  id="middle_name"
                                  placeholder="Middle Name"
                                  label="Middle Name"
                                  fullWidth
                                  {...props.getFieldProps("middle_name")}
                                  error={Boolean(
                                    props.touched.middle_name &&
                                      props.errors.middle_name
                                  )}
                                  helperText={
                                    props.touched.middle_name &&
                                    props.errors.middle_name
                                  }
                                />
                              </Grid>
                              <Grid xs={12}>
                                <TextField
                                  id="family"
                                  placeholder="Last Name"
                                  label="Last Name"
                                  fullWidth
                                  {...props.getFieldProps("family")}
                                  error={Boolean(
                                    props.touched.family && props.errors.family
                                  )}
                                  helperText={
                                    props.touched.family && props.errors.family
                                  }
                                />
                              </Grid>
                            </Stack>
                          ) : (
                            <Stack direction="column">
                              <Typography>
                                <b>{`${props.values.given} ${props.values.middle_name} ${props.values.family}`}</b>
                              </Typography>
                              <ErrorMessage name="given">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                              <ErrorMessage name="family">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>

                      {/* <Grid
                            container
                            direction="row"
                            alignItems="center"
                            minHeight={60}
                            marginTop={editMode ? 1 : 0}>
                            <Grid
                              container
                              item
                              xs={5}>
                              <Typography>
                                Date of Birth
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={7}>
                              {
                                editMode
                                  ?
                                  <TextField
                                    id="birthDate"
                                    placeholder="Date of Birth"
                                    type="date"
                                    fullWidth
                                    {...props.getFieldProps('birthDate')}
                                    error={Boolean(props.touched.birthDate && props.errors.birthDate)}
                                    helperText={props.touched.birthDate && props.errors.birthDate} />
                                  :
                                  <Typography>
                                    <b>{dateYearFormatWithAge(props.values.birthDate)}</b>
                                  </Typography>
                              }
                            </Grid>
                          </Grid> */}

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={5}>
                          <Typography>Gender</Typography>
                        </Grid>

                        <Grid container item xs={7}>
                          {editMode ? (
                            <TextField
                              id="gender"
                              placeholder="Gender"
                              fullWidth
                              select
                              SelectProps={{
                                native: true,
                              }}
                              {...props.getFieldProps("gender")}
                              error={Boolean(
                                props.touched.gender && props.errors.gender
                              )}
                              helperText={
                                props.touched.gender && props.errors.gender
                              }
                            >
                              <option value="" disabled></option>
                              {genderCatalog.map((gender: any) => (
                                <option value={gender.valueCode}>
                                  {gender.valueCode}
                                </option>
                              ))}
                            </TextField>
                          ) : (
                            <Stack direction="column">
                              <Typography>
                                <b>{props.values.gender}</b>
                              </Typography>
                              <ErrorMessage name="gender">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={5}>
                          <Typography>Email</Typography>
                        </Grid>

                        <Grid container item xs={7}>
                          {editMode ? (
                            <>
                              <Grid xs={3}>
                                <TextField
                                  id="emailType"
                                  placeholder="Email Type"
                                  fullWidth
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  {...props.getFieldProps("emailType")}
                                  error={Boolean(
                                    props.touched.emailType &&
                                      props.errors.emailType
                                  )}
                                  helperText={
                                    props.touched.emailType &&
                                    props.errors.emailType
                                  }
                                >
                                  <option value="" disabled></option>
                                  {emailTypeCatalog.map((type: any) => (
                                    <option value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>

                              <Grid xs={9}>
                                <TextField
                                  id="email"
                                  placeholder="Email"
                                  fullWidth
                                  type="email"
                                  {...props.getFieldProps("emailAddress")}
                                  error={Boolean(
                                    props.touched.emailAddress &&
                                      props.errors.emailAddress
                                  )}
                                  helperText={
                                    props.touched.emailAddress &&
                                    props.errors.emailAddress
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <Stack direction="column">
                              <Typography>
                                <b>{`${props.values.emailAddress} (${props.values.emailType})`}</b>
                              </Typography>
                              <ErrorMessage name="emailType">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                              <ErrorMessage name="emailAddress">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={5}>
                          <Typography>Phone</Typography>
                        </Grid>

                        <Grid container item xs={7}>
                          {editMode ? (
                            <>
                              <Grid xs={3}>
                                <TextField
                                  id="phoneType"
                                  placeholder="Phone Type"
                                  fullWidth
                                  select
                                  SelectProps={{
                                    native: true,
                                  }}
                                  {...props.getFieldProps("phoneType")}
                                  error={Boolean(
                                    props.touched.phoneType &&
                                      props.errors.phoneType
                                  )}
                                  helperText={
                                    props.touched.phoneType &&
                                    props.errors.phoneType
                                  }
                                >
                                  <option value="" disabled></option>
                                  {phoneTypesCatalog.map((type: any) => (
                                    <option value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid xs={9}>
                                <InputMasked
                                  id="phoneNumber"
                                  placeholder="Phone Number"
                                  fullWidth
                                  type="phone"
                                  {...props.getFieldProps("phoneNumber")}
                                  error={Boolean(
                                    props.touched.phoneNumber &&
                                      props.errors.phoneNumber
                                  )}
                                  helperText={
                                    props.touched.phoneNumber &&
                                    props.errors.phoneNumber
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <Stack direction="column">
                              <Typography>
                                <b>{`${phoneFormat(
                                  props.values.phoneNumber
                                )} (${props.values.phoneType})`}</b>
                              </Typography>
                              <ErrorMessage name="phoneType">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                              <ErrorMessage name="phoneNumber">
                                {(msg) => (
                                  <small className="validationError">
                                    {msg}
                                  </small>
                                )}
                              </ErrorMessage>
                            </Stack>
                          )}
                        </Grid>
                      </Grid>

                      {/* <Grid
                            container
                            direction="row"
                            alignItems="center"
                            minHeight={60}>
                            <Grid
                              container
                              item
                              xs={5}>
                              <Typography>
                                Language
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={7}>
                              {
                                editMode
                                  ?
                                  <TextField
                                    id="language"
                                    placeholder="Language"
                                    fullWidth
                                    select
                                    SelectProps={{
                                      native: true,
                                    }}
                                    {...props.getFieldProps('language')}
                                    error={Boolean(props.touched.language && props.errors.language)}
                                    helperText={props.touched.language && props.errors.language}>
                                    <option value="" disabled></option>
                                    {
                                      languagesList.map((lang: any) => <option value={lang.display}>{lang.display}</option>)
                                    }
                                  </TextField>
                                  :
                                  <Typography>
                                    <b>{props.values.language}</b>
                                  </Typography>
                              }
                            </Grid>
                          </Grid> */}

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        minHeight={60}
                      >
                        <Grid container item xs={5}>
                          <Typography>Address</Typography>
                        </Grid>

                        <Grid container item xs={7}>
                          {editMode ? (
                            <>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_use"
                                  placeholder="Use"
                                  fullWidth
                                  label="Use"
                                  select
                                  {...props.getFieldProps("address_use")}
                                  error={Boolean(
                                    props.touched.address_use &&
                                      props.errors.address_use
                                  )}
                                  helperText={
                                    props.touched.address_use &&
                                    props.errors.address_use
                                  }
                                >
                                  {addressTypesCatalog.map((type: any) => (
                                    <MenuItem value={type.shortDisplayName}>
                                      {type.shortDisplayName}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_line"
                                  placeholder="Street"
                                  label="Street"
                                  fullWidth
                                  type="address"
                                  {...props.getFieldProps("address_line")}
                                  error={Boolean(
                                    props.touched.address_line &&
                                      props.errors.address_line
                                  )}
                                  helperText={
                                    props.touched.address_line &&
                                    props.errors.address_line
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_line_2"
                                  placeholder="Street 2"
                                  label="Street 2"
                                  fullWidth
                                  type="address"
                                  {...props.getFieldProps("address_line_2")}
                                  error={Boolean(
                                    props.touched.address_line_2 &&
                                      props.errors.address_line_2
                                  )}
                                  helperText={
                                    props.touched.address_line_2 &&
                                    props.errors.address_line_2
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_city"
                                  placeholder="City"
                                  label="City"
                                  fullWidth
                                  {...props.getFieldProps("address_city")}
                                  error={Boolean(
                                    props.touched.address_city &&
                                      props.errors.address_city
                                  )}
                                  helperText={
                                    props.touched.address_city &&
                                    props.errors.address_city
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_state"
                                  placeholder="State"
                                  label="State"
                                  fullWidth
                                  {...props.getFieldProps("address_state")}
                                  error={Boolean(
                                    props.touched.address_state &&
                                      props.errors.address_state
                                  )}
                                  helperText={
                                    props.touched.address_state &&
                                    props.errors.address_state
                                  }
                                />
                              </Grid>
                              <Grid xs={6} marginTop={1}>
                                <TextField
                                  id="address_postal"
                                  placeholder="Zip Code"
                                  label="Zip Code"
                                  fullWidth
                                  {...props.getFieldProps("address_postal")}
                                  error={Boolean(
                                    props.touched.address_postal &&
                                      props.errors.address_postal
                                  )}
                                  helperText={
                                    props.touched.address_postal &&
                                    props.errors.address_postal
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid xs={3}>
                                <Typography>
                                  <b>{props.values.address_use}</b>
                                </Typography>
                              </Grid>

                              <Grid xs={9}>
                                <Typography>
                                  <b>{`${props.values.address_line}, ${props.values.address_line_2}, ${props.values.address_city}, ${props.values.address_state}, ${props.values.address_postal}`}</b>
                                </Typography>
                              </Grid>

                              <Stack>
                                <ErrorMessage name="address_use">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="address_line">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="address_city">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="address_state">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="address_postal">
                                  {(msg) => (
                                    <small className="validationError">
                                      {msg}
                                    </small>
                                  )}
                                </ErrorMessage>
                              </Stack>
                            </>
                          )}
                        </Grid>
                      </Grid>

                      {/* <Grid
                            container
                            direction="row"
                            alignItems="center"
                            minHeight={60}>
                            <Grid
                              container
                              item
                              xs={5}>
                              <Typography>
                                Height
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={7}>
                              <Typography>
                                <b>5 ft. 5"</b>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            minHeight={60}>
                            <Grid
                              container
                              item
                              xs={5}>
                              <Typography>
                                Weight
                              </Typography>
                            </Grid>

                            <Grid
                              container
                              item
                              xs={7}>
                              <Typography>
                                <b>145 lbs.</b>
                              </Typography>
                            </Grid>
                          </Grid> */}
                    </Grid>
                  </ListItem>
                </List>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Stack width={500} alignItems="center" marginTop={50}>
          <CircularProgress />
        </Stack>
      )}


      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Guarantor Info"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          If you cancel your previous changes will be lost, do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert} variant="contained" color="error" sx={{ marginRight: 1 }}>Disagree</Button>
          <Button onClick={handleAgreeAlert} autoFocus variant="contained" color="success">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

    </Drawer>
  );
}
