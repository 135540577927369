import { format, parseISO } from "date-fns";
import moment from "moment";

export function phoneFormat(phoneString: any) {
  let cleaned = ("" + phoneString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }

  return phoneString;
}

const months = Array.from({ length: 12 }, (item, i) =>
  new Date(0, i).toLocaleString("en-US", { month: "short" })
);

export function parseStringToDate(stringDate: string): Date {
  const splitedText = stringDate?.split(/[-\s:T+]/g);
  let year = 2000;
  let month = 0; // values from 0 (jan) - 11 (dec)
  let day = 1; // values from 1 - 31
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (splitedText && splitedText.length >= 6) {
    let date = new Date(stringDate);
    date.toLocaleDateString("en-US");

    return date;
  }

  if (splitedText && splitedText.length >= 3) {
    year = parseInt(splitedText[0]);
    month = parseInt(splitedText[1]) - 1; // values from 0 (jan) - 11 (dec)
    day = parseInt(splitedText[2]); // values from 1 - 31
  }

  return new Date(year, month, day, hours, minutes, seconds);
}

export function dateYearFormat(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()}`;
}

export function getYearAndMonths(stringDate: string) {
  const localDate = parseStringToDate(stringDate);
  const currentDate = parseStringToDate(new Date().toISOString());

  const totalMonths =
    (currentDate.getFullYear() - localDate.getFullYear()) * 12 +
    (currentDate.getMonth() - localDate.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  return `(${years} years, ${months} months)`;
}
export function getYearAndMonthsIncidentDetails(stringDate: string) {
  const localDate = parseStringToDate(stringDate);
  const currentDate = parseStringToDate(new Date().toISOString());

  const totalMonths =
    (currentDate.getFullYear() - localDate.getFullYear()) * 12 +
    (currentDate.getMonth() - localDate.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  if (years == 0) {
    return `(${months} mo)`;
  } else {
    return `(${years} yr)`;
  }
}
export function getYearAndMonthsandDays(stringDate: string) {
  const localDate: any = parseStringToDate(stringDate);
  const currentDate: any = parseStringToDate(new Date().toISOString());
  var fechaInicio = new Date(stringDate).getTime();
  var fechaFin = new Date().getTime();

  var diff = localDate - currentDate;

  const totalMonths =
    (currentDate.getFullYear() - localDate.getFullYear()) * 12 +
    (currentDate.getMonth() - localDate.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  const days = -1 * Math.round(diff / (1000 * 60 * 60 * 24));
  if (days > 30) {
    const monthsAdd = months + 1;
    const daysAdd = days - 30;
    return `(${years} years, ${monthsAdd} m. ${daysAdd} d.ago)`;
  }

  return `(${years} years, ${months} m. ${days} d.ago)`;
}
export function DaysToTals(stringDate: string) {
  const localDate: any = parseStringToDate(stringDate);
  const currentDate: any = parseStringToDate(new Date().toISOString());
  var fechaInicio = new Date(stringDate).getTime();
  var fechaFin = new Date().getTime();

  var diff = localDate - currentDate;

  const totalMonths =
    (currentDate.getFullYear() - localDate.getFullYear()) * 12 +
    (currentDate.getMonth() - localDate.getMonth());
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  const days = -1 * Math.round(diff / (1000 * 60 * 60 * 24));
  if (days > 30) {
    const monthsAdd = months + 1;
    const daysAdd = days - 30;
    return `(${years} years, ${monthsAdd} m. ${daysAdd} d.ago)`;
  }
  return `(${days} d)`;
}
export const parseDateUsingFormat = (inputDate: any, formatDate: any) => {
  const dateObj = parseISO(inputDate);
  const formattedDate = format(dateObj, formatDate);
  return formattedDate;
};
export function dateYearFormatWithAge(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${getYearAndMonths(
    stringDate
  )}`;
}
export function dateYearFormatWithAge5(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${DaysToTals(
    stringDate
  )}`;
}
export function dateYearFormatWithAge7(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${DaysToTals(
    stringDate
  )}`;
}
export function dateYearFormatWithAge3(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${`<br/>`} ${getYearAndMonthsandDays(
    stringDate
  )}`;
}
export function dateYearFormatWithAge2(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${getYearAndMonthsIncidentDetails(
    stringDate
  )}`;
}
export function dateYearFormatWithAge6(stringDate: string, type?: string) {
  if (stringDate === undefined) {
    return "";
  }

  const localDate = parseStringToDate(stringDate.split("T")[0]);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${getYearAndMonthsIncidentDetails(
    stringDate
  )}`;
}
export const formatDate = (date: any) => {
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("en-US", options);
};

export const formaIsoDate = (stringDate: string) => {
  if (stringDate === undefined || stringDate === null) {
    return "";
  }

  const localDate = parseStringToDate(stringDate.split("T")[0]);

  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()} ${getYearAndMonthsIncidentDetails(
    stringDate
  )}`;

  // const options: any = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  // return new Date(date).toLocaleDateString("en-US", options);
};

export const calculateDaysPassedToday = (dateStr: string) => {
  const currentDateTime: any = new Date();
  const inputDateTime: any = new Date(dateStr);

  const timeDifference = Math.floor((currentDateTime - inputDateTime) / 1000);

  const secondsInADay = 86400;
  const secondsInAMonth = 2592000;
  const secondsInAYear = 31536000;

  if (timeDifference < secondsInAMonth) {
    const days = Math.floor(timeDifference / secondsInADay);
    return `${days} d`;
  } else if (timeDifference < secondsInAYear) {
    const months = Math.floor(timeDifference / secondsInAMonth);
    return `${months} mo`;
  } else {
    const years = Math.floor(timeDifference / secondsInAYear);
    return `${years} yr`;
  }
};
export const formatDateForDob = (strDate: string) => {
  if (strDate === undefined) return "";
  if (!strDate) return "";

  const localDate = parseStringToDate(strDate.split("T")[0]);
  return `${
    months[localDate.getMonth()]
  } ${localDate.getDate()}, ${localDate.getFullYear()}`;
};
export function getAgeFromString(stringDate: string): number {
  var today = new Date();
  var birthDate = parseStringToDate(stringDate);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function isDateFn(stringValue: string): boolean {
  const regExp = new RegExp(
    "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
  );
  if (regExp.test(stringValue)) {
    try {
      const testDate = new Date(stringValue);
      if (typeof testDate === "string" && testDate === "Invalid Date") {
        return false;
      }

      if (typeof testDate === "number" && isNaN(testDate)) {
        return false;
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  return false;
}

export const calculateAge = (birthDate: moment.Moment): number => {
  var today = moment();
  var age = today.diff(birthDate, "years");
  var ageAdjustment = today.subtract(age, "years").isBefore(birthDate) ? -1 : 0;
  return age + ageAdjustment;
};
