import { createContext, useState } from "react";

const ComxInteractiveScreenContext = createContext({});

const ComxInteractiveScreenContextProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ComxInteractiveScreenContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </ComxInteractiveScreenContext.Provider>
  );
};

export { ComxInteractiveScreenContext, ComxInteractiveScreenContextProvider };
