import { useEffect, useState } from 'react'
import { urlBackNestApp } from "routes/urls";
import AppVersion from "./AppVersion";

export function getClientCommit() {
    try {
      return {
        commitInfo: require('../../utils/last-commit-info.json')
      };
    }
    catch (error) {
      return {
        error: 'No version information available'
      }
    }
}

export const menuVersionApp = (arr: any) => {
  return arr.map((item: any, i: any) => {
    if(item){
      const {branch, date, shortHash, tag} = item
      
      return {
        title: tag?.startsWith(shortHash) ? branch + `( ${shortHash} )` : shortHash,
        to: '#',
        type: i === 0 ? 'Client' : 'Server',
        date: new Date(date).toDateString(),
        color: i === 1 ? '#FF3030' : '#0045FF'
      }
    }
    return {
      title: 'No Info Avaliable',
      to: '#',
      type: i === 0 ? 'Client' : 'Server',
      date: 'No Info Avaliable',
      color: i === 1 ? '#FF3030' : '#0045FF'
    }
  })
}

export default function FooterInfo (){
    const [clientInfo, setClientInfo] = useState<any>()
    const [serverInfo, setServerInfo] = useState<any>()

    useEffect(()=> {
      const {commitInfo} = getClientCommit()
      getServerCommit()
      setClientInfo(commitInfo)
    },[])

    async function getServerCommit(){
      const resp = await fetch(`${urlBackNestApp}/app-info/last-commit`)
      const json = await resp.json()
      setServerInfo(json)
    }
    
    return <AppVersion clientInfo={clientInfo} serverInfo={serverInfo}/>
}