// material
import { Typography, TextField } from "@material-ui/core";
import { InputMasked } from "utils/inputMasked";

type Props = {
  title: string;
  value?: string;
  showInput: boolean;
  error?: any;
  helperText?: any;
  getFieldProps?: any;
  inputProps?: any;
  type?: any;
  usePhoneMask?: boolean;
};

export default function DetailsText({
  title,
  value,
  showInput,
  error,
  helperText,
  getFieldProps,
  inputProps,
  type,
  usePhoneMask,
}: Props) {
  function getTextField() {
    return usePhoneMask === undefined ? (
      <TextField
        fullWidth
        inputProps={inputProps ? inputProps : {}}
        value={value ? value : getFieldProps.value}
        type={type ? type : "text"}
        {...getFieldProps}
        error={error}
        helperText={helperText}
      />
    ) : (
      <InputMasked
        inputProps={inputProps ? inputProps : {}}
        value={value ? value : getFieldProps.value}
        type={type ? type : "text"}
        {...getFieldProps}
        error={error}
        helperText={helperText}
      />
    );
  }

  return (
    <>
      <Typography
        variant="body2"
        component="p"
        paragraph
        style={{ color: "#919eab" }}
      >
        {title}
      </Typography>
      {showInput ? (
        getTextField()
      ) : (
        <Typography variant="body1" component="p" paragraph>
          <b>{value ? value : getFieldProps.value}</b>
        </Typography>
      )}
    </>
  );
}
