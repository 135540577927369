export const PRACTITIONERS_ACL_PREFIX = "Practitioners";
export const PRACTITIONERS_ALL_ACL = `${PRACTITIONERS_ACL_PREFIX}.*`;
export const ACCESS_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Access`;
export const CREATE_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Create`;
export const CREATE_MANUAL_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Create.Manual`;
export const LINK_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Link`;
export const UNLINK_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Unlink`;
export const GENERAL_PRACTITIONER_PATIENT_ASSIGNMENT = `${PRACTITIONERS_ACL_PREFIX}.Patient.General_Practitioner.Assignment`;

export const GENERAL_PRACTITIONER_PATIENT_UNASSIGNMENT = `${PRACTITIONERS_ACL_PREFIX}.Patient.General_Practitioner.Unassignment`;

export const EDIT_PRACTITIONER_ACL = `${PRACTITIONERS_ACL_PREFIX}.Edit`;
