import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import { urlBackNestApp } from "routes/urls";
import moment from "moment";

const DispositionDialog = ({
  isOpen,
  handlerDisposition,
  handlerIsOpenDispositionDialog,
  saveDispositions,
  withoutReason,
}: any) => {
  const [openDialog, setOpenDialog] = useState(isOpen);
  const [dispositionReasonCatalog, setDispositionReasonCatalog] = useState([]);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  const handleCloseForm = () => {
    setOpenDialog(false);
    handlerIsOpenDispositionDialog(false);
  };

  const [dispositions, setDispositions] = useState<any>({
    reason: "",
    note: "",
    on: moment().format("YYYY-MM-DD HH:mm:ss"),
  });

  const onChangeDispositions = (e: any) => {
    const { name, value } = e.target;
    const disposedOn = moment().format("YYYY-MM-DD HH:mm:ss");
    setDispositions({ ...dispositions, [name]: value, on: disposedOn });
    // saveDispositions({ ...dispositions, [name]: value });
  };

  const handleOnSaveDisposition = (e: any) => {
    handlerDisposition();
    setTimeout(() => {
      handleCloseForm();
    }, 1500);
  };

  useEffect(() => {
    saveDispositions({ ...dispositions });
  }, [dispositions]);

  const getDispositionReason = async () => {
    const dispositionReasonCode = "DISPOSITION_REASONS";
    axios
      .get(`${urlBackNestApp}/catalog/${dispositionReasonCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("encryptUser")}`,
        },
      })
      .then((response) => {
        setDispositionReasonCatalog(response.data);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  useEffect(() => {
    getDispositionReason();
  }, []);

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseForm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="confirm-dialog-title">
        PLEASE CONFIRM THE DISPOSITION ACTION
      </DialogTitle>
      <DialogContent>
        Are you sure you want to execute this disposition?
        <Box
          component="form"
          sx={{
            marginTop: 5,
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          {!withoutReason && (
            <div>
              <TextField
                id="outlined-select-currency"
                select
                label="Disposition Reason"
                name="reason"
                onChange={(e) => onChangeDispositions(e)}
              >
                {dispositionReasonCatalog.map((reason: any) => (
                  <MenuItem value={reason.shortDisplayName}>
                    {reason.shortDisplayName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          )}
          <div>
            <TextField
              sx={{ width: "500px !important" }}
              id="outlined-multiline-static"
              label="Disposition Note"
              multiline
              rows={4}
              name="note"
              onChange={(e) => onChangeDispositions(e)}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseForm} variant="contained" color="error">
          Disagree
        </Button>
        <Button
          onClick={(e) => {
            handleOnSaveDisposition(e);
          }}
          variant="contained"
          color="success"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DispositionDialog;
