import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import RoleBasedGuard, { ACLS, InckNatPortal, PatientAccessAcl, RequestAccessAcl, UsersAccessAcl, OrganizationAccessAcl, IncidentViewAcl, SysAdminAcl } from "guards/RoleBasedGuard";

import PatientEngagementDetails from "pages/patient-engagement/Details";
import { ACCESS_PRACTITIONER_ACL, PRACTITIONERS_ALL_ACL } from "constants/providerDirectoryAcls";
// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: <AuthGuard
        roles={[InckNatPortal]}
        component={DashboardLayout} />,
      children: [
        {
          path: "app",
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/app/patients" replace={false} />,
            },
            { path: "patients", element: <RoleBasedGuard roles={[PatientAccessAcl]} component={PatientsList} /> },
            { path: "patients/:id", element: <RoleBasedGuard roles={[PatientAccessAcl]} component={PatientDetails} /> },
            { path: "patients/new", element: <RoleBasedGuard roles={[PatientAccessAcl]} component={NewPatient} /> },
            { path: "patients/bulkupload", element: <RoleBasedGuard roles={[PatientAccessAcl]} component={BulkUpload} /> }
          ],
        },
        {
          path: "app",
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/app/request" replace={false} />,
            },
            { path: "request", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={OrdersList} /> },
            { path: "request/:id", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={OrderDetails} /> },
            { path: "request/:id/task/:taskId", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={OrderDetails} /> },
            { path: "request/new", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={AddOrder} /> },
          ],
        },
        {
          path: "app",
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/app/tasks" replace={false} />,
            },
            { path: "tasks", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={TasksList} /> },
            { path: "tasks/:taskId", element: <RoleBasedGuard roles={[RequestAccessAcl]} component={OrderDetails} /> },
          ],
        },
        {
          path: "app",
          children: [
            {
              path: "/",
              element: <Navigate to="/dashboard/app/users" replace={false} />,
            },
            { path: 'users', element: <RoleBasedGuard roles={[UsersAccessAcl]} component={UsersList} /> },
            { path: 'users/:userId', element: <RoleBasedGuard roles={[UsersAccessAcl]} component={UserProfile} /> },
            { path: 'users/addUser', element: <RoleBasedGuard roles={[UsersAccessAcl]} component={AddUser} /> }
          ]
        },
        {
          path: 'app',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/app/organizations" replace={false} />
            },
            { path: 'organizations', element: <RoleBasedGuard roles={[OrganizationAccessAcl]} component={Organizations} /> },
            { path: 'organizations/:orgId', element: <RoleBasedGuard component={OrganizationProfile} roles={[OrganizationAccessAcl]} /> },
            { path: 'organizations/addOrganization', element: <RoleBasedGuard roles={[OrganizationAccessAcl]} component={AddOrganization} /> }
          ]
        },
        {
          path: 'app',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/app/fallout-screen" replace={false} />
            },
            { path: 'incident', element: <RoleBasedGuard roles={[IncidentViewAcl]} component={FalloutScreen} /> },
            { path: 'incident/:falloutId', element: <RoleBasedGuard component={FalloutScreenProfile} roles={[IncidentViewAcl]} /> },
            // { path: 'fallout-screen/addOrganization', element: <RoleBasedGuard roles={[OrganizationAccessAcl]} component={AddOrganization} /> }
          ]
        },
        {
          path: 'app',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/app/provider" replace={false} />
            },
            { path: 'provider', element: <RoleBasedGuard roles={[SysAdminAcl, ACCESS_PRACTITIONER_ACL, PRACTITIONERS_ALL_ACL]} component={Provider} /> },
            { path: 'provider/:providerId', element: <RoleBasedGuard component={ProviderProfile} roles={[SysAdminAcl, ACCESS_PRACTITIONER_ACL, PRACTITIONERS_ALL_ACL]} /> },
            // { path: 'fallout-screen/addOrganization', element: <RoleBasedGuard roles={[OrganizationAccessAcl]} component={AddOrganization} /> }
          ]
        },
        {
          path: 'app',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/app/myprofile" replace={false} />
            },
            { path: 'myprofile', element: <RoleBasedGuard roles={ACLS} component={MyProfile} /> },
          ]
        },
        {
          path: 'app',
          children: [
            {
              path: '/',
              element: <Navigate to="/dashboard/app/patient-engagements" replace={false} />
            },
            { path: 'patient-engagements', element: <RoleBasedGuard roles={ACLS} component={PatientEngagementList} /> },
            { path: 'patient-engagements/:patientEngagementId', element: <RoleBasedGuard roles={ACLS} component={PatientEngagementDetails} /> }
          ]
        },
      ]
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace={false} /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [{ path: "/", element: <Navigate to="/dashboard/app/patients" replace={false} /> }],
    },
    { path: "*", element: <Navigate to="/404" replace={false} /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);

// Dashboard
const PatientsList = Loadable(lazy(() => import('../pages/patients/List')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const UsersList = Loadable(lazy(() => import('../pages/users/UsersList')));
const Organizations = Loadable(lazy(() => import('../pages/organizations/OrganizationList')));
const OrganizationProfile = Loadable(lazy(() => import('../pages/organizations/OrganizationProfile')));
const UserProfile = Loadable(lazy(() => import('../pages/users/UserDetails')));
const AddUser = Loadable(lazy(() => import('../pages/users/AddUser')));
const AddOrganization = Loadable(lazy(() => import('../pages/organization/AddOrganization')));
const OrganizationDetails = Loadable(lazy(() => import('../pages/organizations/OrganizationDetails')));

const OrdersList = Loadable(lazy(() => import('../pages/orders/OrderList')));
const FalloutScreen = Loadable(lazy(() => import('../pages/fallout-screen/FalloutScreenList')));
const FalloutScreenProfile = Loadable(lazy(() => import('../pages/fallout-screen/FalloutScreenProfile')));
const Provider = Loadable(lazy(() => import('../pages/provider/ProviderList/ProviderList')));
const ProviderProfile = Loadable(lazy(() => import('../pages/provider/ProviderProfile')));
const AddOrder = Loadable(lazy(() => import('../pages/orders/AddOrder')));
const OrderDetails = Loadable(lazy(() => import('../pages/orders/OrderDetails')));

const TasksList = Loadable(lazy(() => import('../pages/tasks/TasksList')));

const PatientDetails = Loadable(
  lazy(() => import("../pages/patients/PatientDetails"))
);
const NewPatient = Loadable(
  lazy(() => import("../pages/patients/NewPatient"))
);
const BulkUpload = Loadable(lazy(() => import('../pages/bulk-upload/BulkUpload')))

const MyProfile = Loadable(lazy(() => import('../pages/myprofile/Profile')));

const PatientEngagementList = Loadable(lazy(() => import('../pages/patient-engagement/List')));