import useAuth from "hooks/useAuth";


export function AclsComponentCheck(children: any, acls: any){
    const {user} = useAuth()

    return user!.acl.some((item: any) => acls.includes(item)) ? children : null
    
}

export function hasUUIDInURL(url: string): boolean {
    const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
    return uuidPattern.test(url);
}