import React from "react";
import { UIMetaProvider, useUIMeta } from "@ui-schema/ui-schema/UIMeta";
import {
  UIStoreProvider,
  createEmptyStore,
  createStore,
} from "@ui-schema/ui-schema/UIStore";
import { storeUpdater } from "@ui-schema/ui-schema/storeUpdater";
import { injectPluginStack } from "@ui-schema/ui-schema/applyPluginStack";
import { isInvalid } from "@ui-schema/ui-schema/ValidityReporter";
import { createOrderedMap } from "@ui-schema/ui-schema/Utils/createMap";
import { relTranslator } from "@ui-schema/ui-schema/Translate/relT";
import { widgets } from "@ui-schema/ds-material";
import { GridContainer } from "@ui-schema/ds-material/GridContainer";
import moment from 'moment';
import 'moment-timezone';
import { List } from "immutable";
import {
  WidgetProps,
  WithScalarValue,
  StoreSchemaType,
} from "@ui-schema/ui-schema";
import { WidgetDatePicker } from "@ui-schema/material-pickers/WidgetDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import _ from "lodash";

const getExtraProps = (
  schema: StoreSchemaType,
  type: "date" | "date-time" | "time"
) => {
  const data: { [k: string]: any } = {};
  if (
    schema.getIn(["date", "variant"]) === "static" ||
    schema.getIn(["date", "variant"]) === "dialog"
  ) {
    data.clearable = schema.getIn(["date", "clearable"]) as boolean | undefined;
    data.showTodayButton = schema.getIn(["date", "today"]) as
      | boolean
      | undefined;
    data.showToolbar = schema.getIn(["date", "toolbar"]) as boolean | undefined;
  }
  if (type === "date-time" || type === "time") {
    data.ampm = schema.getIn(["date", "ampm"]);
  }
  return data;
};

const CustomDatePicker: React.FC<WidgetProps & WithScalarValue> = (props) => {
  const { schema } = props;
  const Picker =
    schema.getIn(["date", "variant"]) === "dialog"
      ? MobileDatePicker
      : schema.getIn(["date", "variant"]) === "static"
      ? StaticDatePicker
      : DesktopDatePicker;
  const pickerProps = React.useMemo(() => getExtraProps(schema, "date"), [
    schema,
  ]);
  return (
    <WidgetDatePicker
      {...props}
      Picker={Picker}
      schema={
        // fix fatal error when missing `views`, seems tu be bug in @mui/x
        schema.getIn(["date", "variant"]) === "static"
          ? schema.setIn(["date", "views"], List(["year", "month", "day"]))
          : schema
      }
      pickerProps={pickerProps}
    />
  );
};

const customWidgets = { ...widgets };
customWidgets.custom = {
  ...widgets.custom,
  Date: CustomDatePicker,
};

// const schema2 = createOrderedMap({
//   type: "object",
//   properties: {
//     CONFIRM_MEMBER_INFO_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Confirm Member Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Confirm Member Validated By",
//             },
//           },
//         },
//       ],
//     },
//     CONFIRM_GUARANTOR_INFO_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Confirm Guarantor Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Confirm Guarantor Validated By",
//             },
//           },
//         },
//       ],
//     },
//     CONFIRM_NAT_OPTIN_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Confirm NAT Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Confirm NAT Validated By",
//             },
//             modifiedFields: {
//               type: "object",
//               properties: {
//                 opt_in_decision: {
//                   type: "boolean",
//                   title: "Opt In Decision",
//                   deleteOnEmpty: true,
//                 },
//                 method: { type: "string", deleteOnEmpty: true },
//                 notes: { type: "string", deleteOnEmpty: true },
//               },
//             },
//           },
//         },
//       ],
//     },
//     SCHEDULE_NAT_SESSION_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Schedule NAT Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Schedule NAT Validated By",
//             },
//             modifiedFields: {
//               type: "object",
//               properties: {
//                 dateHour: { type: "string", deleteOnEmpty: true },
//                 type: { type: "string", deleteOnEmpty: true },
//               },
//             },
//           },
//         },
//       ],
//     },
//     REVIEW_NAT_REPORT_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Review NAT Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Review NAT Validated By",
//             },
//           },
//         },
//       ],
//     },
//     CONFIRM_CM_OPTIN_ITEM: {
//       allOf: [
//         {
//           type: "object",
//           properties: {
//             validatedOn: {
//               type: "string",
//               format: "date",
//               title: "Confirm CM Validated On",
//               view: {
//                 sizeMd: 6,
//                 shrink: true,
//               },
//             },
//             validatedBy: {
//               type: "string",
//               format: "email",
//               title: "Confirm CM Validated By",
//             },
//             modifiedFields: {
//               type: "object",
//               properties: {
//                 opt_in_decision: {
//                   type: "boolean",
//                   title: "Opt In Decision",
//                   deleteOnEmpty: true,
//                 },
//                 type: { type: "string", deleteOnEmpty: true },
//                 notes: { type: "string", deleteOnEmpty: true },
//               },
//             },
//           },
//         },
//       ],
//     },
//   },
// });

// const values = {
//   CONFIRM_MEMBER_INFO_ITEM: {
//     validatedOn: moment(new Date()).format("YYYY-MM-DD")
//   }
// };

const GridStack = injectPluginStack(GridContainer);

export const Generator = ({ dataSchema, data, saveStore }: any) => {
  const { scope = {} } = data;
  const { checklistItem = {} } = scope;

  const defaultStructure = {
    ...(checklistItem?.CONFIRM_MEMBER_INFO_ITEM && checklistItem?.CONFIRM_MEMBER_INFO_ITEM?.validatedOn && { CONFIRM_MEMBER_INFO_ITEM: { validatedOn: moment(new Date(checklistItem.CONFIRM_MEMBER_INFO_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM && checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn && { CONFIRM_GUARANTOR_INFO_ITEM: { validatedOn: moment(new Date(checklistItem.CONFIRM_GUARANTOR_INFO_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM && checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn && { CONFIRM_NAT_OPTIN_ITEM: { validatedOn: moment(new Date(checklistItem.CONFIRM_NAT_OPTIN_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM && checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn && { SCHEDULE_NAT_SESSION_ITEM: { validatedOn: moment(new Date(checklistItem.SCHEDULE_NAT_SESSION_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.REVIEW_NAT_REPORT_ITEM && checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn && { REVIEW_NAT_REPORT_ITEM: { validatedOn: moment(new Date(checklistItem.REVIEW_NAT_REPORT_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM && checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn && { CONFIRM_CM_OPTIN_ITEM: { validatedOn: moment(new Date(checklistItem.CONFIRM_CM_OPTIN_ITEM.validatedOn)).format("YYYY-MM-DD") }}),
    ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM && checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.dateHour && { SCHEDULE_NAT_SESSION_ITEM: { dateHour: moment(new Date(checklistItem.SCHEDULE_NAT_SESSION_ITEM.dateHour)).format("YYYY-MM-DD") }}),
  };

  const formattedData = _.merge(defaultStructure, checklistItem);

  const schema: any = createOrderedMap(dataSchema);

  const [store, setStore] = React.useState(() =>
    createStore(createOrderedMap(formattedData))
  );

  React.useEffect(() => {
    saveStore(store.valuesToJS());
  }, [store]);

  // or create empty store, based on the schema type:
  // const [store, setStore] = React.useState(() => createEmptyStore(schema.get('type'));

  const onChange = React.useCallback(
    (actions) => {
      setStore(storeUpdater(actions));
    },
    [setStore]
  );

  return (
    <UIMetaProvider widgets={widgets} t={relTranslator}>
      <UIStoreProvider store={store} onChange={onChange} showValidity={true}>
        <GridStack isRoot schema={schema} />
      </UIStoreProvider>
    </UIMetaProvider>
  );
};

export default Generator;
