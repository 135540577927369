import { APIPagination } from "./apiQuery";

export function getPaginatedApiResponse<T>(
  response: any,
): APIPagination<T> {
  const fhirPage: APIPagination<T> = {
    data: [],
    previousPage: undefined,
    nextPage: undefined,
    firstPage: undefined,
    total: undefined,
  };

  fhirPage.data = response?.data ?? [];
  fhirPage.previousPage = response?.previousPage;
  fhirPage.nextPage = response?.nextPage;
  fhirPage.firstPage = response?.firstPage;
  fhirPage.total = response?.total ?? 0;

  console.log('DEBUG getPaginatedApiResponse fhirPage: ', fhirPage);
  

  // bundle?.link?.forEach((item: any) => {
  //   if (item.relation !== undefined && item.url !== undefined) {
  //     const itemUrlQuery = new URLSearchParams(item.url);
  //     const pageToken = itemUrlQuery.get("_page_token");
  //     const encodedPageToken =
  //       pageToken === null ? undefined : encodeURIComponent(pageToken);
  //     const urlStr: string = item.url;
  //     const regex1 = /([A-z]+\/{1}[?])/g;
  //     const splitStr = urlStr.split(regex1);
  //     const queries = splitStr[2] === undefined ? "" : splitStr[2];

  //     if (item.relation === "next") {
  //       if (pageToken !== undefined) fhirPage.nextPage = encodedPageToken;
  //     } else if (item.relation === "first") {
  //       fhirPage.firstPage = queries;
  //     } else if (item.relation === "self") {
  //       // if (encodedPageToken === undefined) {
  //       //   fhirPage.selfPageToken = queries;
  //       // } else {
  //       //   fhirPage.selfPageToken = encodedPageToken;
  //       // }
  //     }
  //   }
  // });

  // if (bundle.total !== undefined) fhirPage.total = bundle.total;

  return fhirPage;
}
