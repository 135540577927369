import {
  Attachment,
  CodeableConcept,
  ContactPoint,
  HumanName,
  Identifier,
  Meta,
  Period,
  Reference,
  Resource as ResourceFHIR,
} from "./Fhir";

export type GeneralPractitionerResourceType =
  | "Organization"
  | "PractitionerRole"
  | "Practitioner";

export enum GeneralPractitionerAssigmentAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export interface Address {
  line?: string[];
  city?: string;
  district?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  use?: string;
  type?: string;
  period?: {
    start?: string;
    end?: string;
  };
}

export interface GeneralPractitionerData {
  id: string;
  fullName: string;
  email?: string;
  phone?: string;
  npi?: string;
  address: Address;
  isVerified: boolean;
  resource?: FhirResource;
}

export interface AssignableGeneralPractitioner {
  resourceId: string;
  resourceType: GeneralPractitionerResourceType;
  practitioner?: GeneralPractitionerData | null;
  organization?: GeneralPractitionerData | null;
  role?: string | null;
  roleCode?: string | null;
  specialty?: string | null;
}

export interface GeneralPractitionerQueryParams {
  search?: string;
  status?: string;
  patientId: string;
  type?: string;
}

export interface ResourceQueryParams {
  search?: string;
  resourceType: GeneralPractitionerResourceType;
}

export interface Resource {
  id: string;
  name: string;
  resourceType: string;
}

export interface Coding {
  code?: string;
  display?: string;
}

export interface PractitionerRoleReference {
  id: string;
  role: Coding;
  practitioner: Coding | null;
  organization: Coding | null;
}

export interface FhirResource {
  id: string;
  resourceType: GeneralPractitionerResourceType;
  identifier?: Identifier[];
}

export interface PractitionerElement {
  birthDate?: string;
  active?: boolean;
  gender?: string;
  telecom?: ContactPoint[];
  address?: Address[];
  qualification?: PractitionerQualification[];
  identifier?: Identifier[];
  name?: HumanName[];
  photo?: Attachment[];
  communication?: CodeableConcept[];
  meta?: Meta;
}

export interface Practitioner
  extends Partial<FhirResource>,
    PractitionerElement {}

export interface PractitionerFHIR extends ResourceFHIR, PractitionerElement {}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
  UNKOWN = "unknow",
}

export interface PractitionerWithGeneralData {
  practitioner: Practitioner;
  generalData: GeneralPractitionerData;
}

export interface PractitionerQualification {
  identifier?: Identifier[];
  code: CodeableConcept;
  period?: Period;
  issuer?: Reference;
}

export enum QualificationType {
  LICENSE = "License",
  CERTIFICATION = "Certification",
  SPECIALTY = "Specialty",
  OTHER = "Other",
  TAXONOMY = "Classification/Specialty",
}

export interface SimpleQualification {
  code: string;
  id: string;
  type: string;
  name: string;
  period: string;
  sortingDate: moment.Moment;
}

export interface SimpleLicense {
  code: string;
  id: string;
  title: string;
  number: string;
  period: string;
  sortingDate: moment.Moment;
}

export interface ProviderCodeMapping {
  concepts: {
    provider: string;
    fhir: string;
  }[];
  default: string;
}
