import { Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  highlight: {
    backgroundColor: "#000",
    color: "#FFF",
    paddingBottom: "8px",
    marginTop: "8px",
  },
});

interface KeyValueGridProps {
  label: string;
  value: string;
  tooltipString?: string;
  highlight?: boolean;
}

export default function KeyValueGrid({
  label,
  value,
  tooltipString,
  highlight,
}: KeyValueGridProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      className={highlight ? classes.highlight : undefined}
      spacing={1}
      wrap="nowrap"
    >
      <Grid
        container
        item
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
        justifyContent="flex-end"
      >
        <Typography align="right">
          <b>{label}</b>
        </Typography>
      </Grid>
      <Grid container item xs={8} sm={8} md={8} lg={8} xl={8} zeroMinWidth>
        <Tooltip title={tooltipString ? tooltipString : value}>
          <Typography noWrap>{value}</Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
